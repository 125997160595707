
import React,  {Component} from 'react';
import { Redirect } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import queryString from 'query-string';
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table'
import './moncss.css';
import { Button,Form,Modal} from 'react-bootstrap';
import * as ls from "local-storage";
import { isExpired, decodeToken }from "react-jwt";

const key = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCcKGs66ZIthYniCtkSfY7gHmCr0cPcnBay2pXfkkhARA155XMrwDgac8GUrfAj2KaCxi6eZjfGlHJ+nONfpEvTr+brC57crF3sMEnaYYskVyV8WcRAQkc784pavddzcV6qwRYezoJJylpZDQ9TOUwjOEAiQmDMaFCAYmQQIDAQAB"
const CryptoJS = require("crypto-js");

var errocode = 0;

class Listenounou extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
        selectedFile: null,
        dureesound:'',
        selectedOption: null,
        idtype : 0,
        iduserupdate : 0,
        activeuserupdate : 0,
        nom: '',
        prenoms: '',
        mail: '',
        tokenuser:[],
        show_input:'text',
        tokenexist: true,
        tokenvalue: '',
        listnounou: [],
        iduser:0,
        modalupdate: false,
        modaldelete: false,
        usertype:'',
        societe:'',
        editorContentHtml:'',
        liendocument: 'https://www.nannylou.com/mobilewbs/nounou/',
        active:false,
      };

      
      this.selectsociety = this.selectsociety.bind(this);
      this.selectusertype = this.selectusertype.bind(this);
        
  }

  componentWillMount() {

    document.title = "Liste des Nounous";

    let token = ls.get('token');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    const datauser = JSON.stringify(myDecodedToken.id).replace(/\"/g, "");
  
    
    var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
    var originaldata = bytes.toString(CryptoJS.enc.Utf8);
    var userdata  = originaldata.split('-');

    if(userdata[2] !== 'admin'){

      this.setState({ tokenvalue: ''})
      return
  
    }else{
  
      this.setState({ tokenvalue: userdata[2]})
  
    }

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          //window.location.reload(false);
          this.setState({ reload: true})
          
    }

  }
    
  async componentDidMount() { 

    document.title = "Liste des Nounous";

    let token = ls.get('token');

    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    const responsenounoulist = await fetch('https://www.nannylou.com/mobilewbs/utilisateur/allnounou.php',{                       
      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded','authentification': 'Bearer '+ token},
      });
    const datanounou = await responsenounoulist.json();
    
    if(JSON.stringify(datanounou).replace(/\"/g, "") !== 'NOT'){
      this.setState({ listnounou: datanounou})
    }  

}  

showmodalupdate(iduser,nom,prenom,active){

  this.setState({
    iduser:iduser,
    nom: nom,
    prenoms:prenom,
    active:active,
    modalupdate: true
  })

}

validenounou(){

  this.testtoken();

  const token = ls.get('token');
  

  fetch(`https://www.nannylou.com/mobilewbs/nounou/activenounou.php`,{                  
        
      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded','authentification': 'Bearer '+ token},
      body: queryString.stringify({
        idnnl: this.state.iduser,
        active: Number(this.state.active),
      })

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(JSON.stringify(data).replace(/\"/g, "") === 'NOT'){
      this.setState({ errorform: true, errormessage: 'Erreur de la base de données.' });
      return;
    }

    if(JSON.stringify(data).replace(/\"/g, "") !== 'NOT'){
      this.setState({ listnounou: data})
    }  

    this.setState({
      iduser:0,
      nom: '',
      prenoms:'',
      active:false,
      modalupdate: false,
    })

    //this.refreshdata();

    })
    .catch(err => {
        alert("Erreur :" + errocode + ", "+err)
        this.setState({ tokenuser: err});
    }); 

    document.title = "Liste des Nounous";

}

showdeletenounou(iduser,nom,prenom){
  
  this.setState({
    iduser:iduser,
    nom: nom,
    prenoms:prenom,
    modaldelete: true
  })

}

deletenounou(){

  this.testtoken();

  const token = ls.get('token');

  fetch(`https://www.nannylou.com/mobilewbs/nounou/supprimernounou.php`,{                  
        
      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded','authentification': 'Bearer '+ token},
      body: queryString.stringify({
        idnounou: this.state.iduser,
      })

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

      if(JSON.stringify(data).replace(/\"/g, "") === 'User not found'){
        this.setState({ errorform: true, errormessage: 'Utilisateur non reconnu.' });
        return;
      }

      if(JSON.stringify(data).replace(/\"/g, "") === 'Error'){
        this.setState({ errorform: true, errormessage: 'Erreur de la base de données.' });
        return;
      }
  
      if(JSON.stringify(data).replace(/\"/g, "") !== 'NOT'){
        this.setState({ listnounou: data})
      }  

    this.setState({
      iduser: 0,
      nom: "",
      prenoms:"",
      modaldelete: false
    })

    //this.refreshdata();

    })

    .catch(err => {
      alert("Erreur :" + errocode + ", "+err)
      this.setState({ tokenuser: err});
    }); 

    document.title = "Liste des Nounous";

}

selectusertype(e) {
  this.setState({ usertype: e.target.value });
}

selectsociety(e) {
  this.setState({ societe: e.target.value });
}



onChange = (editorState) => {
  this.setState({
    editorState
  });
  
}

testtoken(){
      
  let token = ls.get('token');

  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    alert("Session expiré! Veuillez vous reconnecter.")
    ls.clear()
    this.setState({ typeuser: null})   
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    alert("Session expiré! Veuillez vous reconnecter.")
    ls.clear()
    this.setState({ typeuser: null}) 
    document.location.href = '/login';
    return;
  }

}

  
  render() {  

          return (
              
                <div className="App" >
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/Login"/>
                              

                            :

                            null

                    }
                    
                    {this.state.modalupdate === true ?

                    <Modal

                    show={this.state.modalupdate}
                    onHide={ () => this.setState({modalupdate:false})}
                    dialogClassName="modal-dialog"
                    aria-labelledby="example-custom-modal-styling-title" centered
                    >
                      <Modal.Body>
                                  
                      <div className="d-flex flex-row justify-content-center">
                                        
                          <div className="d-flex flex-column" style={{margin:"20px"}}>
                              
                              <div className="d-flex flex-column">
                                  <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>Voulez-vous validée l'inscription de &nbsp;</p>
                                  <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.prenoms} {this.state.nom}?</p>
                                  
                                  <form>

                                  <div className="form-group" style={{margin:'10px'}}>
                                    <Form.Group>
                                      <Form.Check type={"checkbox"}>
                                        <Form.Check.Label>Validé</Form.Check.Label>
                                          <Form.Check.Input
                                            type={"checkbox"}
                                            defaultChecked={parseInt(this.state.active)}
                                            onClick={(e) => {
                                              this.setState({ active: e.target.checked });
                                            }}
                                          />
                                      </Form.Check>
                                    </Form.Group>
                                  </div>

                                  <div className='row text-white d-flex align-items-center'>
                                              
                                        <Button className="btn"style={{ margin:"20px", width:'150px', borderRadius:'25px'}} onClick={ () => this.setState({modalupdate:false})} variant="danger">Fermer</Button>
                                        <Button className="btn" style={{ margin:"10px",right: 0, width:'150px', borderRadius:'25px'}} onClick={() => this.validenounou()} variant="primary">Valider</Button>

                                  </div>
                                  
                                </form>
                              </div>
                              
                          </div>

                      </div>    

                    </Modal.Body>
                    </Modal>

                    :

                    null

                    }

                  {this.state.modaldelete === true ?

                  <Modal

                  show={this.state.modaldelete}
                  onHide={ () => this.setState({modaldelete:false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                                
                    <div className="d-flex flex-row justify-content-center">
                                      
                        <div className="d-flex flex-column" style={{margin:"20px"}}>
                            
                            <div className="d-flex flex-column">
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>Voulez-vous supprimer &nbsp;</p>
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.prenoms} {this.state.nom}?</p>
                                
                                <form>

                                <div className='row text-white d-flex align-items-center'>
                                            
                                      <Button className="btn"style={{ margin:"20px", width:'150px', borderRadius:'25px'}} onClick={ () => this.setState({modaldelete:false})} variant="primary">Fermer</Button>
                                      <Button className="btn" style={{ margin:"10px",right: 0, width:'150px', borderRadius:'25px'}} onClick={() => this.deletenounou()} variant="danger">Supprimer</Button>

                                </div>
                                
                              </form>
                            </div>
                            
                        </div>

                    </div>    

                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }
                 
                  <div className='centrervue' style={{ paddingTop:'100px'}}>

                  {this.state.listnounou.length>0 ?

                          <Table>
                          <Thead>
                            <Tr>
                              <Th >Date d'inscription</Th>
                              <Th >Identité</Th>
                              <Th >Contact</Th>
                              <Th style={{minWidth:"150px"}}>Heure de garde</Th>
                              <Th >Expériences</Th>
                              <Th >Documents</Th>
                              <Th >Validé</Th>
                              <Th >Supprimer</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {this.state.listnounou.map((row) => (
                              <Tr key={row.idutilisateur} style={{backgroundColor: parseInt(row.active)==0? "#E99DCB":"white"}} className="tableborder">
                              <Td >{parseInt(new Date(Date.parse(row.dateinscriptionnounou.replace(/[-]/g, '/'))).getDate()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateinscriptionnounou.replace(/[-]/g, '/'))).getDate())}</label>:parseInt(new Date(Date.parse(row.dateinscriptionnounou.replace(/[-]/g, '/'))).getDate())} - {parseInt(new Date(Date.parse(row.dateinscriptionnounou.replace(/[-]/g, '/'))).getMonth())+1 < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateinscriptionnounou.replace(/[-]/g, '/'))).getMonth())+1}</label>:parseInt(new Date(Date.parse(row.dateinscriptionnounou.replace(/[-]/g, '/'))).getMonth())+1} - {new Date(Date.parse(row.dateinscriptionnounou.replace(/[-]/g, '/'))).getFullYear()}</Td>
                              <Td>{row.prenomsnounou} {row.nomnounou} <br/>{row.codepostale} {row.ville} <br/>{row.adresse}<br/></Td>
                              <Td><a style={{textAlign:'left', textDecoration:'none'}} href={"mailto:" + row.email}>Mail: {row.email}</a> <br/> <a style={{textAlign:'left', textDecoration:'none'}} href={"tel:" + row.telephone}>Tél: {row.telephone}</a></Td>
                              <Td style={{minWidth:"150px"}}><p>{row.heuregarde}</p></Td>
                              <Td><p>{row.experience}</p></Td>
                              <Td>
                                {row.pitj != '' ? <a href={this.state.liendocument + row.pitj} target="_blank">Pièce d'identité<br/></a> : null}
                                {row.cvitale != '' ? <a href={this.state.liendocument + row.cvitale} target="_blank">Carte Vitale<br/></a> : null}
                                {row.cv != '' ? <a href={this.state.liendocument + row.cv} target="_blank">CV<br/></a> : null}
                                {row.diplome != '' ? <a href={this.state.liendocument + row.diplome} target="_blank">DIPLOME<br/></a> : null}
                                {row.permis != '' ? <a href={this.state.liendocument + row.permis} target="_blank">PERMIS<br/></a> : null}
                                {row.cj != '' ? <a href={this.state.liendocument + row.cj} target="_blank">CASSIER JUDICIAIRE<br/></a> : null}
                              </Td>
                              <Td ><button className="btn btn-primary btn-circle btn-xl" style={{borderRadius:'25px'}} onClick={() => this.showmodalupdate(row.idnounou,row.nomnounou,row.prenomsnounou,row.active)}><span><EditIcon /></span></button></Td>
                              <Td ><button className="btn btn-danger btn-circle btn-xl" style={{borderRadius:'25px'}} onClick={() => this.showdeletenounou(row.idnounou,row.nomnounou,row.prenomsnounou)}><span><DeleteIcon /></span></button></Td>
                              {/*<TableCell ><button type="submit" className="btn btn-danger btn-circle btn-xl" style={{borderRadius:'25px'}} onClick={() => this.showdeleteqcm(row.idutilisateur,row.nomnounou,row.prenomsnounou)}><span><DeleteIcon /></span></button></TableCell>*/}
                  
                            </Tr>
                            ))}
                          </Tbody>
                          </Table>
                      
                    
                     


                
                    :

                    null

                  }
                  
                  </div> 

                </div>
                
          );
        }
    
}

export default Listenounou;
