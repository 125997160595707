import './App.css';
import './moncss.css';
import React,  {Alert,Component} from 'react';
import { Nav,Navbar,Button,Form,Container,Row,Col} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import Toast from 'react-bootstrap/Toast'
import Card from 'react-bootstrap/Card'
import logo from './logo.png';
import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import { Link } from 'react-scroll';
import axios from 'axios';
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import MailIcon from '@material-ui/icons/Mail';
import CallIcon from '@material-ui/icons/Call';
import HouseIcon from '@material-ui/icons/House';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
//import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Redirect  } from 'react-router-dom';
import * as ls from "local-storage";

class Promotion extends Component{
    
    constructor(props) {
    super(props);

    this.state = {
      paypalpay:false,
      paiementeasy: false,
      tarif:0.00,
      listtarif:[],
    };
        
  }
    
  async componentDidMount() {

    const response = await fetch('https://www.nannylou.com/mobilewbs/tarif/selectalltarifpromotion.php');
    const data = await response.json();
      this.setState({ listtarif: data[0]})

      document.title = "Promotion"
        
    };


    componentDidUpdate(prevProps) {

      window.scrollTo(0, 0)

    };

    paiement = (prixnet,tariftype) => {  

      this.setState({ tarif: prixnet})

      var typeuser = ls.get('type');

      if (typeuser === null){

        alert("Veuillez-vous inscrire ou se connecter.");

        document.location.href = '/login';
       
      }

      ls.set('tariftype', tariftype);
      ls.set('montant', prixnet);
      ls.set('typeoffre', 'promotion');

      if(tariftype == 'PONCTUEL'){
        window.open(this.state.listtarif.lienponctuel);
      }

      if(tariftype == 'PREMIUM'){
        window.open(this.state.listtarif.lienpremium);
      }

      if(tariftype == 'DELUXE'){
        window.open(this.state.listtarif.liendeluxe);
      }
      
      //alert("Paiement effectué à la somme totale de " + prixnet + "€.");
    }
   
    
  render() {

          return (
              
                <div className="App" style={{paddingTop:"100px", marginRight:"10px"}}>

                        {this.state.paiementeasy == true ?
                            

                            <Redirect push to="/inscription"/>
                              

                            :

                            null

                        }

                    <Fade bottom>
                    <div className='align-items-center'>                         
                        <p style={{color:"#e67e22", fontWeight:"bold", fontSize:"2em"}} className="animatennltext">Obtenez votre e-carte cadeau.</p>
                        <p style={{fontWeight:"bold", fontSize:"1.5em"}} >Cher(e) client(e),</p>
                        <p style={{fontSize:"1em"}} >Nous sommes ravis que vous ayez fait appel à aux services NannyLou dédiés à la garde d'enfant.</p>
                        <p style={{fontSize:"1em"}} >Ces e-cartes cadeaux sont valables jusqu'au 30 avril 2023.</p>
                        <p style={{fontSize:"1em"}} >Elles peuvent être également offertes à une personne de votre entourage mais ne peut être utilisée qu'une fois.</p>
                        <p style={{fontSize:"1em"}} >En choisissant l’une de nos e-cartes cadeaux, vous bénéficiez des services de la nounou durant tout le mois en illimité.</p>
                        <p style={{fontSize:"1em"}} >Grâce à cet achat, vous paierez uniquement le salaire de la nounou en fonction des heures effectuées (salaire compris entre 8,5€ et 12€/h). Dans le cas où vous offrez cette e-carte cadeau, c'est ce document qu'il faudra nous présenter.</p>
                        <p style={{fontSize:"1em"}} >Ces e-cartes cadeaux sont valables jusqu'au 30 avril 2023.</p>
                       
                        <p style={{fontSize:"1em"}} >Nous vous remercions de votre confiance et nous vous souhaitons à vous et votre famille de belles fêtes.</p>
                    </div>
                    </Fade>
                   
                    <Fade bottom>
                                           
                      <div className='listedata' >

                        <Card style={{ width: '20rem', height: isBrowser ? '650px' : 'auto', borderRadius:'25px', margin:'25px' }} className="cardborder">                                               
                                                                                      
                        <Card.Body className="cardlist">
                                                
                            <Card.Title style={{fontSize:"2em"}}>PONCTUEL</Card.Title>
                            <p style={{fontSize:"2em", color:"#e67e22", fontWeight:"bold", textDecoration:"line-through"}}>{this.state.listtarif.tarifponctuelnormal} €/demande</p>
                            <Card.Text style={{fontSize:"2em", color:"#e67e22", fontWeight:"bold"}}>{this.state.listtarif.tarifponctuel} €/demande</Card.Text>
                            {/*<p style={{fontSize:"0em", textDecoration:"line-through"}}>.</p>*/}
                            <div className='row text-white d-flex align-items-center centerdata'>
                                <span></span><br/>
                                <span></span><br/>
                                <span></span><br/>
                                <p className="descriptiontarifs">Abonnement ponctuel (autonome)</p>
                                <span></span><br/>
                                <p className="descriptiontarifs">{this.state.listtarif.tarifponctuel}€/demande + tarif horaire de la nounou ( de {this.state.listtarif.tarifnounoujourmin}€ min à {this.state.listtarif.tarifnounoujourmax}€ max/heure)</p>
                                <span></span><br/>
                                <p className="descriptiontarifs"></p>
                                <span></span><br/>
                                <span></span><br/>
                      
                                <Button style={{
                                  background: 'linear-gradient(45deg, #E99DCB 30%, #8099E9 90%)',
                                  border: 0,
                                  borderRadius: 3,
                                  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                                  height: 48,
                                  width:200,
                                  borderRadius:'25px'
                                  }} onClick={() => this.paiement(this.state.listtarif.tarifponctuel,'PONCTUEL')} >PAYER</Button>
                                  
                            </div>
                            
                          </Card.Body>

                        </Card>   

                        <Card style={{ width: '20rem', height: isBrowser ? '650px' : 'auto', borderRadius:'25px', margin:'25px' }} className="cardborder">                                               
                                                                                      
                          <Card.Body>
                                                
                            <Card.Title style={{fontSize:"2em"}}>PREMIUM</Card.Title>
                            <p style={{fontSize:"2em", color:"#e67e22", fontWeight:"bold", textDecoration:"line-through"}}>{this.state.listtarif.	tarifpremiumnormal} €/mois</p>
                            <Card.Text style={{fontSize:"2em", color:"#e67e22", fontWeight:"bold"}}>{this.state.listtarif.tarifspremium} €/mois</Card.Text>
                            {/*<p style={{fontSize:"2em", textDecoration:"line-through"}}>358,8 €</p>*/}
                            <div className='row text-white d-flex align-items-center centerdata'>
                                <span></span><br/>
                                <p className="descriptiontarifs">Abonnement premium (accompagnement)</p>
                                <span></span><br/>
                                <p className="descriptiontarifs">{this.state.listtarif.tarifspremium}€/mois</p>
                                
                                <p className="descriptiontarifs">+ tarif horaire de la nounou ( de {this.state.listtarif.tarifnounoujourmin}€ min à {this.state.listtarif.tarifnounoujourmax}€ max/heure)</p>
                                
                                <p className="descriptiontarifs">+ Conseils et accompagnement</p>
                                
                                <p className="descriptiontarifs">+ suivi et remplacement nounou si besoin</p>
                                
                                <Button style={{
                                  background: 'linear-gradient(45deg, #E99DCB 30%, #8099E9 90%)',
                                  border: 0,
                                  borderRadius: 3,
                                  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                                  height: 48,
                                  width:200,
                                  borderRadius:'25px'
                                  }} onClick={() => this.paiement(this.state.listtarif.tarifspremium,'PREMIUM')} >PAYER</Button>
                                  
                            </div>
                            
                          </Card.Body>

                        </Card>   

                        <Card style={{ width: '20rem', height: isBrowser ? '650px' : 'auto', borderRadius:'25px', margin:'25px' }} className="cardborder">                                               
                                                                                      
                          <Card.Body>
                                                
                            <Card.Title style={{fontSize:"2em"}}>DELUXE</Card.Title>
                            <p style={{fontSize:"2em", color:"#e67e22", fontWeight:"bold", textDecoration:"line-through"}}>{this.state.listtarif.tarifdeluxenormal} €/mois</p>
                            <Card.Text style={{fontSize:"2em", color:"#e67e22", fontWeight:"bold"}}>{this.state.listtarif.tarifdeluxe} € /mois</Card.Text>
                            {/*<p style={{fontSize:"2em", textDecoration:"line-through"}}>717,6 €</p>*/}
                            <div className='row text-white d-flex align-items-center centerdata'>
                                <span></span><br/>
                                <p className="descriptiontarifs">Abonnement de luxe (Nous faisons tout pour vous)</p>
                                <span></span><br/>
                                <p className="descriptiontarifs">+ tarif horaire de la nounou ( de {this.state.listtarif.tarifnounoujourmin}€ min à {this.state.listtarif.tarifnounoujourmax}€ max/heure)</p>
                               
                                <p className="descriptiontarifs">+ Conseils et accompagnement</p>
                                
                                <p className="descriptiontarifs">+ Suivi et remplacement nounou si besoin</p>
                                
                                <p className="descriptiontarifs">+ Fiche de paye</p>
                                
                                <Button style={{
                                  background: 'linear-gradient(45deg, #E99DCB 30%, #8099E9 90%)',
                                  border: 0,
                                  borderRadius: 3,
                                  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                                  height: 48,
                                  width:200,
                                  borderRadius:'25px'
                                  }} onClick={() => this.paiement(this.state.listtarif.tarifdeluxe,'DELUXE')} >PAYER</Button>
                                  
                            </div>
                            
                          </Card.Body>

                        </Card>   

                        </div>
      
                    </Fade>

                </div>
                
          );
        }
    
}

export default Promotion;
