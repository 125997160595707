import './App.css';
import './moncss.css';
import React,  {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { Button} from 'react-bootstrap';
import queryString from 'query-string';
import parse from 'html-react-parser';

class TESTQCM extends Component{
    
  constructor(props) {
    super(props);
    this.state = {
      iddqcmtestcache :[],
      iddqcmtest :[],
      listqcm :[],
      nomcomplet:'',
      allresult :[],
      mailuser:props.match.params.mailuser,
      time: {},
      seconds: 1200,
      validation:false,
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs){
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }

  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });
    
    // Check if we're at zero.
    if (seconds == 0) { 
      clearInterval(this.timer);
    }
  }
    
    async componentDidMount() {

      let timeLeftVar = this.secondsToTime(this.state.seconds);
      this.setState({ time: timeLeftVar });
      

      const response = await fetch('https://www.nannylou.com/mobilewbs/qcm/Selectallqcm.php');
      const data = await response.json();
      this.setState({ listqcm: data})

      if(data.length>0){
        
        this.randomnumber(data.length)

      }    
        
    };

     randomnumber= (data) => {

      var min = Math.ceil(11);
      var max = Math.floor(parseInt(data) + 10);

      const random = Math.floor(Math.random() * (max - min+1)) + min;
      
      this.testvalue(random, data)
     
    }

  testvalue = (random, data) =>{

    if(parseInt(random) < 11){
      this.refreshlist(data)
      return
    }

    if(this.state.iddqcmtest.length == 0){

      this.state.iddqcmtest.push({ idqcmtestask: random, idqcmtestaskask : random.toString() + 'ask', reponsequestion : ''})
      this.refreshlist(data)
      return
      
    }

    if(this.state.iddqcmtest.length<20){

      const resultat = this.state.iddqcmtest.find( qcmtestvalue => parseInt(qcmtestvalue.idqcmtestask) === parseInt(random));

          if(resultat === undefined){      
            
            this.state.iddqcmtest.push({ idqcmtestask: random, idqcmtestaskask : random.toString() + 'ask', reponsequestion : ''})
            
            this.refreshlist(data)
            
          }else{

            this.refreshlist(data)

          }
      
    }

  }

  refreshlist  (data) {
    
    if(this.state.iddqcmtest.length<20){

      this.randomnumber(data);
      
    }else{
      
      this.setState({ iddacmtestcache: this.state.iddqcmtest });

      document.title = 'Test QUIZ'

      this.startTimer()

    }

  }



  sendresult(){

    if (this.state.mailuser !== "") {

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      if (!pattern.test(this.state.mailuser)) {

          alert("Votre mail est invalide, veuillez contacter l'amdinistrateur s'il vous plait.");
          
          return;

      }

    }

    if(this.state.nomcomplet.length == 0){
      alert('Veuillez saisir votre nom complet.')
      return;
    }

    this.state.iddqcmtest.forEach((item)=>{

      var idresult = parseInt(item.idqcmtestask)

      let elementexist = document.getElementsByName(item.idqcmtestask).length;

      if(elementexist>0){

        var element = document.getElementById(idresult);
        var elementquestion = document.getElementById(item.idqcmtestaskask);

          if(element.value !== ''){
            this.state.allresult.push({question : elementquestion.value, reponse: element.value}) 
          }else{
            this.state.allresult.push({question : elementquestion.value, reponse: 'Pas de réponse.'}) 
          }
            
        
      }

    })


    this.envoiemail()

  }

  envoiemail () {

    var chronoresult = '';

    if(this.state.seconds>0){
      chronoresult = 'Test effectué avant 20 minutes.' + '<br/>'+ '<br/>'
    }else{
      chronoresult = 'Test effectué après 20 minutes.' + '<br/>'+ '<br/>'
    }

    var resultatfinal = '';

    resultatfinal += chronoresult;

    this.state.allresult.forEach((item)=>{
      
      resultatfinal += item.question + item.reponse  + '<br/>'+ '<br/>' + '<br/>'+ '<br/>'
    })
  
    fetch("https://www.nannylou.com/mailer/sendmailrecrutement.php",{
                        
        method:'post', 
        headers: {'Content-Type':'application/x-www-form-urlencoded'},                   
        body: queryString.stringify({
            nom: this.state.nomcomplet,
            email: this.state.mailuser,
            titre: 'Résultat QUIZ ' + this.state.nomcomplet,
            message: resultatfinal.replace(/(\r\n|\n)/g, "<br/>"),
        })

    })
        .then(data => data.text())
          .then((text) => { 

                 //NotificationManager.success('Message envoyé', 'Succès',3000);


               this.setState({
                nomcomplet: '',
                mailuser: '',
                allresult: [],
                iddqcmtestcache :[],
                iddqcmtest :[],
                listqcm :[],
                seconds:'0',
                validation:true,
              });

          }).catch(function (error) {
            alert(error)
            //NotificationManager.warning(error, 'Erreur',5000);
          });

}

componentWillMount() {
      window.scrollTo(0, 0)
    }


    
  render() {

          return (

                <div >

                  {this.state.mailuser == '' ?

                    <Redirect push to="/Accueil"/>

                  :

                    <div></div>

                  }


                  {this.state.validation === false ?

                    <div>

                      <div style={{paddingTop:'150px', marginBottom:"20px"}}>

                      <div className="d-flex flex-column textombrenoir" >
                          <p style={{ fontSize:"40px", textAlign:"center",fontWeight: "bold",marginBottom:'0px'}}>Questions quiz</p>
                          
                          {this.state.seconds>0 ?

                            <p style={{ fontSize:"30px", textAlign:"center",fontWeight: "bold",marginBottom:'0px'}}> {this.state.time.m}min : {this.state.time.s}sec</p>
                            
                            :

                            <p style={{ fontSize:"30px", textAlign:"center",fontWeight: "bold",marginBottom:'0px'}}> Temps écoulé. Continuez à repondre aux questions.</p>

                          }
                          
                      </div>
                      
                                            
                        {this.state.iddqcmtest.length>0 ?
                        <div style={{margin:'20px'}}>
                          {this.state.iddqcmtest.map((row) => (
                            <div>

                              {this.state.listqcm.map((rowtext) => (

                                <div>
                                  {parseInt(rowtext.idqcm) == parseInt(row.idqcmtestask) ?

                                    <div style={{marginTop:'30px'}}>

                                      <input ref={row.idqcmtestaskask} id={row.idqcmtestaskask} name={row.idqcmtestaskask} value={rowtext.qcm} style={{width:"0px",height:"0px"}}/>
                                      {parse(rowtext.qcm)}
                                      <div >
                                        <input style={{marginTop:'0px'}} id={parseInt(row.idqcmtestask)} ref={parseInt(row.idqcmtestask)} name={parseInt(row.idqcmtestask)} type="text" className="form-control"/>
                                      </div>

                                    </div>

                                    :

                                    null

                                  }
                                </div>

                              ))}
                                                          
                            </div>
                              ))}

                                  <div style={{marginTop:'30px'}}>
                                      <p>Quel est votre nom complet?</p>
                                      <div >
                                        <input style={{marginTop:'-10px'}} value={this.state.nomcomplet} onChange={(e) => this.setState({ nomcomplet: e.target.value})} type="text" className="form-control"/>
                                      </div>
                                    </div>
                        </div>
                          
                        :

                        null
                      }

                      </div>



                        <div className="d-flex justify-content-center">
                          <Button className="btn" style={{ backgroundColor: "#FF7E5A", borderRadius:"8px",
                                                    border: 0,
                                                    height: 48,
                                                    width:200,
                                                    marginTop:'20px',
                                                    marginBottom:'80px'}} onClick={() => this.sendresult()} >Validez</Button>
                        </div>

                      </div>

                    :

                      <div style={{paddingTop:'150px'}}>

                        <div className="d-flex flex-column textombrenoir" >

                            <p style={{ fontSize:"40px", textAlign:"center",fontWeight: "bold",marginBottom:'0px'}}>Merci d'avoir effectué le test. Nous vous contacterons plus tard pour la réponse.</p>
                          
                        </div>

                      </div>

                  }
                    
                    
                  
                </div>
          );
        }
    
}

export default TESTQCM;
