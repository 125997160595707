import './App.css';
import './moncss.css';
import React,  {Component} from 'react';
import Card from 'react-bootstrap/Card'
import Fade from 'react-reveal/Fade';
import { isBrowser} from "react-device-detect";
import * as ls from "local-storage";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { isExpired, decodeToken}from "react-jwt";

const key = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCcKGs66ZIthYniCtkSfY7gHmCr0cPcnBay2pXfkkhARA155XMrwDgac8GUrfAj2KaCxi6eZjfGlHJ+nONfpEvTr+brC57crF3sMEnaYYskVyV8WcRAQkc784pavddzcV6qwRYezoJJylpZDQ9TOUwjOEAiQmDMaFCAYmQQIDAQAB"
const CryptoJS = require("crypto-js");

class Parents extends Component{
    
    constructor(props) {
    super(props);

    this.state = {
      paypalpay:false,
      tarif:0.00,
      listtarif:[],
      listtarifcse:[],
      lienimage: process.env.PUBLIC_URL +'images/',
      idcseparent:0,
    };
        
  }
    
  async componentDidMount() {

    let token = ls.get('token');

    if(token === null){
      const response = await fetch('https://www.nannylou.com/mobilewbs/tarif/selectalltarif.php');
      const data = await response.json();
      this.setState({ listtarif: data[0],idcseparent:0})
    }else{

      const myDecodedToken = decodeToken(token);

      if(myDecodedToken == null){
        ls.clear()
        this.setState({ typeuser: null})
        return;
      }
      const isMyTokenExpired = isExpired(token);
  
      if(isMyTokenExpired == true){
        ls.clear()
        this.setState({ typeuser: null})
        return;
      }
  
      const datauser = JSON.stringify(myDecodedToken.id).replace(/\"/g, "");
  
      var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
      var originaldata = bytes.toString(CryptoJS.enc.Utf8);
      var userdata  = originaldata.split('-');
  
      const response = await fetch('https://www.nannylou.com/mobilewbs/tarif/selectalltarif.php');
      const data = await response.json();
      this.setState({ listtarif: data[0],idcseparent:parseInt(userdata[6])})
  
      const responsecse = await fetch('https://www.nannylou.com/mobilewbs/tarif/selectalltarifcse.php');
      const datacse = await responsecse.json();
      this.setState({ listtarifcse: datacse[0],idcseparent:parseInt(userdata[6])})

    }

      document.title = "Pour les parents"
        
    };

    paiement = (prixnet,tariftype) => {  
      
      this.setState({ tarif: prixnet})

      this.testtoken();

      if(tariftype == 'PONCTUEL'){

        if(parseInt(this.state.idcseparent)>0){

          window.open(this.state.listtarifcse.lienponctuel);

        }else{

          window.open(this.state.listtarif.lienponctuel);

        }
        
      }

      if(tariftype == 'PREMIUM'){

        if(parseInt(this.state.idcseparent)>0){

          window.open(this.state.listtarifcse.lienpremium);

        }else{

          window.open(this.state.listtarif.lienpremium);

        }

      }

      if(tariftype == 'DELUXE'){

        if(parseInt(this.state.idcseparent)>0){

          window.open(this.state.listtarifcse.liendeluxe);

        }else{

          window.open(this.state.listtarif.liendeluxe);

        }

      }
      
      //alert("Paiement effectué à la somme totale de " + prixnet + "€.");
    }

    testtoken(){
      
      let token = ls.get('token');

      const myDecodedToken = decodeToken(token);

      if(myDecodedToken == null){
        ls.clear()
        this.setState({ typeuser: null})
        alert("Veuillez-vous inscrire ou se connecter.");
        document.location.href = '/login';
        return;
      }
      const isMyTokenExpired = isExpired(token);

      if(isMyTokenExpired == true){
        ls.clear()
        this.setState({ typeuser: null})
        alert("Veuillez-vous inscrire ou se connecter.");
        document.location.href = '/login';
        return;
      }

    }

    componentWillMount() {
      window.scrollTo(0, 0)
    }
   
    
  render() {

          return (
              
                <div className="App" style={{paddingTop:isBrowser?"90px":"90px",overFlowX: "hidden", backgroundColor:"#FFF4FB"}}>

                    {/*

                    <Fade bottom>
                    <div className='align-items-center'>                         
                        <p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2em"}}>Pour les parents</p>

                    </div>
                    </Fade>

                    */}

                    <img src={this.state.lienimage + "bebe-parent-header.png"} style={{width:"100%", borderRadius:"0% 0% -50% -50%"}} className="imageparentavantage"></img>

                    <div className="titlegardeparent" style={{marginTop:"-20px"}}>Garde d'enfants 24h/24</div>

                    <div style={{color:"black",fontSize:isBrowser?"32px":"20px", margin:"20px"}}>Besoin d'une solution pour la garde de vos enfants ?</div>

                    <div  style={{color:"#6C6C6C", fontSize: "1.2em",textAlign:"left", marginLeft:"10%", marginRight:"10%", width:"80%"}}>
                        <p>En effet, il arrive souvent que l'on rentre tard le soir ou que l'on travaille en horaires décalés, voire même la nuit pour certains. Parfois, on a simplement envie de passer du temps seul(e) ou accompagné(e).</p>
                        <p>C'est pourquoi NannyLou a mis en place un système de géolocalisation en temps réel, vous aurez l'opportunité de repérer nos Nannies passionnées, expérimentées, agréées, diplômées et/ou certifiées à proximité de chez vous ou de votre lieu de travail.</p>
                        <p>Vous pourrez accéder à leur agenda puis contactez celles qui répondent à vos critères !</p>
                        <p>Une fois la prestation terminée, vous aurez la possibilité de noter votre Nanny sur l'application et de partager votre expérience.</p>
                    </div>

                    <div className="fondblurbleu">

                    </div>

                    <div className="fondblurrose">

                    </div>

                    <div className={isBrowser?"cadreavantageparents":""}>

                    

                      <Fade bottom>
                      {/*<div className="titleparents" style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"20px", textAlign:'center'}}>Vos avantages</div>*/}
                      <div className="avantagesparents">
                          <div className="container">

                        
                          <div className="container" style={{zIndex:9999}}>

                              <div className="row">

                                <div className="col-sm">

                                  <div className="row" style={{marginTop:"40px"}}>
                                    <div>
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                        <div className="soustitleavantagesparents">
                                          <span><CheckCircleIcon style={{color:"#FF7E5A", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                        </div>
                                    </div>
                                    <div >
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                        <div className="soustitleavantagesparents" style={{minHeight:isBrowser?"80px":"0px"}}>
                                        <p style={{fontWeight:"500", fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>Faire appel 24h/24 à une Nanny même le week-end.</p>
                                        <span></span><br/>
                                        </div>
                                    </div>
                                  </div>

                                  <div className="row" style={{marginTop:isBrowser?"20px":"0px"}}>
                                    <div>
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                        <div className="soustitleavantagesparents">
                                          <span><CheckCircleIcon style={{color:"#FF7E5A", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                        </div>
                                    </div>
                                    <div >
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                        <div className="soustitleavantagesparents" style={{minHeight:isBrowser?"80px":"0px"}}>
                                        <p style={{fontWeight:"500", fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>Avoir accès aux profils des Nannies, leurs disponibilités et leurs tarifs.</p>
                                        </div>
                                    </div>

                                  </div>

                                  <div className="row" style={{marginTop:"20px"}}>
                                    <div>
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                        <div className="soustitleavantagesparents">
                                          <span><CheckCircleIcon style={{color:"#FF7E5A", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                        </div>
                                    </div>
                                    <div >
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                        <div className="soustitleavantagesparents" style={{minHeight:isBrowser?"80px":"0px"}}>
                                        <p style={{fontWeight:"500", fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>Connaître et calculer le montant de la garde envisagée.</p>
                                        </div>
                                    </div>

                                  </div>

                                  <div className="row" style={{marginTop:"20px"}}>
                                    <div>
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                        <div className="soustitleavantagesparents">
                                          <span><CheckCircleIcon style={{color:"#FF7E5A", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                        </div>
                                    </div>
                                    <div >
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                        <div className="soustitleavantagesparents" style={{minHeight:isBrowser?"160px":"0px"}}>
                                        <p style={{fontWeight:"500", fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>Télécharger tous les documents obligatoires au recrutement d’une Nanny (contrat, conditions d&#8217;emploi, rémunération, déclaration et fin du contrat de travail).</p>
                                        </div>
                                    </div>

                                  </div>

                                  <div className="row" style={{marginTop:"20px"}}>
                                    <div>
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                        <div className="soustitleavantagesparents" >
                                          <span><CheckCircleIcon style={{color:"#FF7E5A", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                        </div>
                                    </div>
                                    <div >
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                        <div className="soustitleavantagesparents" style={{minHeight:isBrowser?"80px":"0px"}}>
                                        <p style={{fontWeight:"500", fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>Bénéficier des aides fiscales (CAF, réduction d&rsquo;impôt, complément libre choix d&rsquo;activité).</p>
                                        </div>
                                    </div>

                                  </div>

                                </div>

                                <div className="col-sm">

                                  <div className="row" style={{marginTop:"40px"}}>
                                    <div>
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                        <div className="soustitleavantagesparents">
                                          <span><CheckCircleIcon style={{color:"#FF7E5A", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                        </div>
                                    </div>
                                    <div >
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                        <div className="soustitleavantagesparents" style={{minHeight:isBrowser?"100px":"0px"}}>
                                        <p style={{fontWeight:"500", fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>Faire appel à des professionnels tous diplômés du secteur de la petite enfance.</p>
                                        </div>
                                    </div>

                                  </div>

                                  <div className="row" style={{marginTop:"20px"}}>
                                    <div>
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                        <div className="soustitleavantagesparents">
                                          <span><CheckCircleIcon style={{color:"#FF7E5A", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                        </div>
                                    </div>
                                    <div >
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                        <div className="soustitleavantagesparents" style={{minHeight:isBrowser?"80px":"0px"}}>
                                        <p style={{fontWeight:"500", fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>Géolocaliser les Nannies disponibles en temps réel.</p>
                                        </div>
                                    </div>

                                  </div>

                                  <div className="row" style={{marginTop:"20px"}}>
                                    <div>
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                        <div className="soustitleavantagesparents">
                                          <span><CheckCircleIcon style={{color:"#FF7E5A", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                        </div>
                                    </div>
                                    <div >
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                        <div className="soustitleavantagesparents" style={{minHeight:isBrowser?"80px":"0px"}}>
                                        <p style={{fontWeight:"500", fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>Trouver facilement une autre famille en cas de garde partagée.</p>
                                        </div>
                                    </div>

                                  </div>

                                  <div className="row" style={{marginTop:"20px"}}>
                                    <div>
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                        <div className="soustitleavantagesparents">
                                          <span><CheckCircleIcon style={{color:"#FF7E5A", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                        </div>
                                    </div>
                                    <div >
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                        <div className="soustitleavantagesparents" style={{minHeight:isBrowser?"160px":"0px"}}>
                                        <p style={{fontWeight:"500", fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>Avoir accès aux numéros des PMI, RAM ou Pajemploi pour s’informer.</p>
                                        
                                        {isBrowser?
                                        
                                          <div>

                                            <span></span><br/>
                                            <span></span><br/>

                                          
                                          </div>

                                          :

                                          null

                                        }
                                        
                                        </div>
                                    </div>

                                  </div>

                                  <div className="row" style={{marginTop:"20px"}}>
                                    <div>
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                        <div className="soustitleavantagesparents">
                                          <span><CheckCircleIcon style={{color:"#FF7E5A", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                        </div>
                                    </div>
                                    <div >
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                        <div className="soustitleavantagesparents" style={{minHeight:isBrowser?"80px":"0px"}}>
                                        <p style={{fontWeight:"500", fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>Noter les Nannies à l’issue de leur prestation.</p>
                                        </div>
                                    </div>

                                  </div>

                                </div>

                              </div>

                          </div>
                          
                          </div>

                      </div>
                      </Fade>

                    </div>

                    <Fade bottom>


                    <div className="tarifsparentsdiv" >

                      <p className="tarifsparents" style={{color:"#003459", fontWeight:"500", fontSize:"32px", textAlign:'center'}}>Nos tarifs sont sous forme d'abonnement en fonction de vos besoins.</p>  
                      <div className='listedata' >

                        <Card style={{ width: '20rem', height: parseInt(this.state.idcseparent)>0 ? '940px' : '900px', borderRadius:'25px', margin:'25px' }} className="cardcolortext">                                               
                                                                                      
                        <Card.Body className="cardlist">
                                                
                            <Card.Title style={{fontSize:"30px", lineHeight:"45px", textAlign:"left", margin:"10px"}} >PONCTUEL</Card.Title>

                            {parseInt(this.state.idcseparent)>0?

                              <Card.Text style={{fontSize:"32px", color:"#0C7EA7", textAlign:"left",margin:'10px',textDecoration:"line-through"}}>{this.state.listtarif.tarifentrepriseponctuel} €/demande</Card.Text>

                              :

                              null
                          
                            }

                            {parseInt(this.state.idcseparent)>0?

                            <Card.Text style={{fontSize:"32px", color:"#ff4e00", textAlign:"left",margin:'10px'}}>{this.state.listtarifcse.tarifentrepriseponctuel} €/demande</Card.Text>

                            :

                            <Card.Text style={{fontSize:"32px", color:"#0C7EA7", textAlign:"left",margin:'10px'}}>{this.state.listtarif.tarifentrepriseponctuel} €/demande</Card.Text>

                            }

                            
                            {/*<p style={{fontSize:"0em", textDecoration:"line-through"}}>.</p>*/}
                            <div className='row text-white d-flex '>

                                <p style={{fontSize:"20px", color:"black", textAlign:"left",margin:'10px'}} >Abonnement ponctuel (autonome)</p>
                                <span></span><br/>

                                <div className="row" style={{marginTop:"20px"}}>
                                  <div>
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                      <div className="soustitleavantagesparents">
                                        <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                      </div>
                                  </div>
                                  <div >
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                      <div className="soustitleavantagesparents" >
                                      <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>{parseInt(this.state.idcseparent)>0?this.state.listtarifcse.tarifentrepriseponctuel:this.state.listtarif.tarifentrepriseponctuel}€/demande</p>
                                      </div>
                                  </div>
                                </div>

                                <div className="row" style={{marginTop:"20px"}}>
                                  <div>
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                      <div className="soustitleavantagesparents">
                                        <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                      </div>
                                  </div>
                                  <div >
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                      <div className="soustitleavantagesparents" >
                                      <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>tarif horaire de la nounou (de {this.state.listtarif.tarifnounoujourmin}€ min à {this.state.listtarif.tarifnounoujourmax}€ max/heure)</p>
                                      </div>
                                  </div>
                                </div>

                                <div className='boutonpayer' onClick={() => this.paiement(this.state.listtarif.tarifentrepriseponctuel,'PONCTUEL')}>
                                    <p  className="textepayer" style={{textAlign:"center"}}>PAYER</p>
                                </div>

                            </div>
                            
                          </Card.Body>

                        </Card>   

                        <Card style={{ width: '20rem', height: parseInt(this.state.idcseparent)>0 ? '940px' : '900px', borderRadius:'25px', margin:'25px' }} className="cardcolortext">                                               
                                                                                      
                          <Card.Body className="cardlist">
                                                
                          <Card.Title style={{fontSize:"30px", lineHeight:"45px", textAlign:"left", margin:"10px"}}>PREMIUM</Card.Title>

                          {parseInt(this.state.idcseparent)>0?

                          <Card.Text style={{fontSize:"32px", color:"#0C7EA7", textAlign:"left",margin:'10px',textDecoration:"line-through"}}>{this.state.listtarif.tarifentreprisestandard} €/mois</Card.Text>

                          :

                          null

                          }

                          {parseInt(this.state.idcseparent)>0?

                          <Card.Text style={{fontSize:"32px", color:"#ff4e00", textAlign:"left",margin:'10px'}}>{this.state.listtarifcse.tarifentreprisestandard} €/mois</Card.Text>

                          :

                          <Card.Text style={{fontSize:"32px", color:"#0C7EA7", textAlign:"left",margin:'10px'}}>{this.state.listtarif.tarifentreprisestandard} €/mois</Card.Text>

                          }

                            {/*<p style={{fontSize:"2em", textDecoration:"line-through"}}>358,8 €</p>*/}
                            <div className='row text-white d-flex'>
              
                                <p style={{fontSize:"20px", color:"black", textAlign:"left",margin:'10px'}}>Abonnement premium (accompagnement)</p>
                                
                                <span></span><br/>

                                <div className="row" style={{marginTop:"20px"}}>
                                  <div>
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                      <div className="soustitleavantagesparents">
                                        <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                      </div>
                                  </div>
                                  <div >
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                      <div className="soustitleavantagesparents" >
                                      <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>{parseInt(this.state.idcseparent)>0?this.state.listtarifcse.tarifentreprisestandard:this.state.listtarif.tarifentreprisestandard}€/mois</p>
                                      </div>
                                  </div>
                                </div>

                                <div className="row" style={{marginTop:"20px"}}>
                                  <div>
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                      <div className="soustitleavantagesparents">
                                        <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                      </div>
                                  </div>
                                  <div >
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                      <div className="soustitleavantagesparents" >
                                      <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Engagement sur 12 mois</p>
                                      </div> 
                                  </div>
                                </div>

                                <div className="row" style={{marginTop:"20px"}}>
                                  <div>
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                      <div className="soustitleavantagesparents">
                                        <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                      </div>
                                  </div>
                                  <div >
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                      <div className="soustitleavantagesparents" >
                                      <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>tarif horaire de la nounou (de {this.state.listtarif.tarifnounoujourmin}€ min à {this.state.listtarif.tarifnounoujourmax}€ max/heure)</p>
                                      </div>
                                  </div>
                                </div>

                                <div className="row" style={{marginTop:"20px"}}>
                                  <div>
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                      <div className="soustitleavantagesparents">
                                        <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                      </div>
                                  </div>
                                  <div >
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                      <div className="soustitleavantagesparents" >
                                      <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Accompagnement et conseil sans administratif</p>
                                      </div>
                                  </div>
                                </div>

                                <div className="row" style={{marginTop:"20px"}}>
                                  <div>
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                      <div className="soustitleavantagesparents">
                                        <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                      </div>
                                  </div>
                                  <div >
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                      <div className="soustitleavantagesparents" >
                                      <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Suivi et remplacement nounou si besoin</p>
                                      </div>
                                  </div>
                                </div>

                                <div className='boutonpayer' onClick={() => this.paiement(this.state.listtarif.tarifentreprisestandard,'PREMIUM')}>
                                    <p  className="textepayer" style={{textAlign:"center"}}>PAYER</p>
                                </div>
                               
                            </div>
                            
                          </Card.Body>

                        </Card>   

                        <Card style={{ width: '20rem', height: parseInt(this.state.idcseparent)>0 ? '940px' : '900px', borderRadius:'25px', margin:'25px' }} className="cardcolortext">                                               
                                                                                      
                          <Card.Body className="cardlist">
                                                
                          <Card.Title style={{fontSize:"30px", lineHeight:"45px", textAlign:"left", margin:"10px"}}>DELUXE</Card.Title>

                          {parseInt(this.state.idcseparent)>0?

                          <Card.Text style={{fontSize:"32px", color:"#0C7EA7", textAlign:"left",margin:'10px',textDecoration:"line-through"}}>{this.state.listtarif.tarifentrepriseluxe} €/demande</Card.Text>

                          :

                          null

                          }

                          {parseInt(this.state.idcseparent)>0?

                          <Card.Text style={{fontSize:"32px", color:"#ff4e00", textAlign:"left",margin:'10px'}}>{this.state.listtarifcse.tarifentrepriseluxe} €/mois</Card.Text>

                          :

                          <Card.Text style={{fontSize:"32px", color:"#0C7EA7", textAlign:"left",margin:'10px'}}>{this.state.listtarif.tarifentrepriseluxe} €/mois</Card.Text>

                          }

                            {/*<p style={{fontSize:"2em", textDecoration:"line-through"}}>717,6 €</p>*/}
                            <div className='row text-white d-flex '>
                                
                                <p style={{fontSize:"20px", color:"black", textAlign:"left",margin:'10px'}}>Abonnement deluxe</p>
                                <p style={{fontSize:"20px", color:"black", textAlign:"left",marginLeft:'10px'}}>Nous faisons tout pour vous</p>
                                

                                <div className="row" style={{marginTop:"20px"}}>
                                  <div>
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                      <div className="soustitleavantagesparents">
                                        <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                      </div>
                                  </div>
                                  <div >
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                      <div className="soustitleavantagesparents" >
                                      <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>{parseInt(this.state.idcseparent)>0?this.state.listtarifcse.tarifentrepriseluxe:this.state.listtarif.tarifentrepriseluxe}€/mois</p>
                                      </div>
                                  </div>
                                </div>

                                <div className="row" style={{marginTop:"20px"}}>
                                  <div>
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                      <div className="soustitleavantagesparents">
                                        <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                      </div>
                                  </div>
                                  <div >
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                      <div className="soustitleavantagesparents" >
                                      <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Engagement sur 12 mois</p>
                                      </div> 
                                  </div>
                                </div>

                                <div className="row" style={{marginTop:"20px"}}>
                                  <div>
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                      <div className="soustitleavantagesparents">
                                        <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                      </div>
                                  </div>
                                  <div >
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                      <div className="soustitleavantagesparents" >
                                      <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>tarif horaire de la nounou (de {this.state.listtarif.tarifnounoujourmin}€ min à {this.state.listtarif.tarifnounoujourmax}€ max/heure)</p>
                                      </div>
                                  </div>
                                </div>

                                <div className="row" style={{marginTop:"20px"}}>
                                  <div>
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                      <div className="soustitleavantagesparents">
                                        <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                      </div>
                                  </div>
                                  <div >
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                      <div className="soustitleavantagesparents" >
                                      <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Accompagnement et conseil avec administratif</p>
                                      </div>
                                  </div>
                                </div>

                                <div className="row" style={{marginTop:"20px"}}>
                                  <div>
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                      <div className="soustitleavantagesparents">
                                        <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                      </div>
                                  </div>
                                  <div >
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                      <div className="soustitleavantagesparents" >
                                      <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Suivi et remplacement nounou si besoin</p>
                                      </div>
                                  </div>
                                </div>

                                <div className="row" style={{marginTop:"20px"}}>
                                  <div>
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                      <div className="soustitleavantagesparents">
                                        <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                      </div>
                                  </div>
                                  <div >
                                      {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                      <div className="soustitleavantagesparents" >
                                      <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Gestion complète de l'établissement des fiches de paie</p>
                                      </div>
                                  </div>
                                </div>

                                <div className='boutonpayer' onClick={() => this.paiement(this.state.listtarif.tarifentrepriseluxe,'DELUXE')}>
                                    <p  className="textepayer" style={{textAlign:"center"}}>PAYER</p>
                                </div>

                                {/* 
                                <Button style={{
                                  background: 'linear-gradient(45deg, #E99DCB 30%, #8099E9 90%)',
                                  border: 0,
                                  borderRadius: 3,
                                  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                                  height: 48,
                                  width:200,
                                  borderRadius:'25px'
                                  }} onClick={() => this.paiement(this.state.listtarif.tarifentrepriseluxe,'DELUXE')} >PAYER</Button>
                                  
                                  */}
                                  
                            </div>
                            
                          </Card.Body>

                        </Card>   

                        </div>

                      </div>
      
                    </Fade>
                    

                </div>
                
          );
        }
    
}

export default Parents;
