
import React,  {Component} from 'react';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import './moncss.css';
import { Button,Modal} from 'react-bootstrap';
import * as ls from "local-storage";
import {Table, Thead, Tbody, Tr, Td} from 'react-super-responsive-table';
import { isExpired, decodeToken}from "react-jwt";

const key = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCcKGs66ZIthYniCtkSfY7gHmCr0cPcnBay2pXfkkhARA155XMrwDgac8GUrfAj2KaCxi6eZjfGlHJ+nONfpEvTr+brC57crF3sMEnaYYskVyV8WcRAQkc784pavddzcV6qwRYezoJJylpZDQ9TOUwjOEAiQmDMaFCAYmQQIDAQAB"
const CryptoJS = require("crypto-js");

var errocode = 0;


class Listeentreprise extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
        selectedFile: null,
        dureesound:'',
        selectedOption: null,
        idtype : 0,
        iduserupdate : 0,
        activeuserupdate : 0,
        nom: '',
        prenoms: '',
        mail: '',
        tokenuser:[],
        show_input:'text',
        tokenexist: true,
        tokenvalue: '',
        listentreprise: [],
        iduser:0,
        modalupdate: false,
        modaldelete: false,
        usertype:'',
        societe:'',
        editorContentHtml:'',
        liendocument: 'https://www.nannylou.com/mobilewbs/entreprise/',
      };

      
      this.selectsociety = this.selectsociety.bind(this);
      this.selectusertype = this.selectusertype.bind(this);
        
  }

  componentWillMount() {

    document.title = "Liste des Entreprises";

    let token = ls.get('token');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    const datauser = JSON.stringify(myDecodedToken.id).replace(/\"/g, "");
  
    
    var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
    var originaldata = bytes.toString(CryptoJS.enc.Utf8);
    var userdata  = originaldata.split('-');

    if(userdata[2] !== 'admin'){

      this.setState({ tokenvalue: ''})
      return
  
    }else{
  
      this.setState({ tokenvalue: userdata[2]})
  
    }

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          //window.location.reload(false);
          this.setState({ reload: true})
          
    }

  }
    
  async componentDidMount() { 

    document.title = "Liste des Entreprises";

    let token = ls.get('token');

    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      ls.clear()
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    const responseentrepriselist = await fetch('https://www.nannylou.com/mobilewbs/utilisateur/allentreprise.php',{                       
      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded','authentification': 'Bearer '+ token},
      });
    const dataentreprise = await responseentrepriselist.json();
    
    if(JSON.stringify(dataentreprise).replace(/\"/g, "") !== 'NOT'){
      this.setState({ listentreprise: dataentreprise})
    }  

}  



showdeleteqcm(iduser,nom,prenom){
  
  this.setState({
    iduser:iduser,
    nom: nom,
    prenoms:prenom,
    modaldelete: true
  })

}

deleteqcm(){

  fetch(`https://www.nannylou.com/mobilewbs/qcm/supprimerqcm.php`,{                  
        
      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded'},
      body: queryString.stringify({
        idqcm: this.state.idqcmupdate,
      })

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(JSON.stringify(data).replace(/\"/g, "") === 'NOT'){
    this.setState({ errorform: true, errormessage: 'QCM non reconnu' });
    return;
    }

    if(JSON.stringify(data).replace(/\"/g, "") === 'Errodb'){
    this.setState({ errorform: true, errormessage: 'Maintenance du site en cours, veuillez réessayer plus tard.' });
    return;
    }

    this.setState({
      idqcmupdate: 0,
      qcmtext: '',
      modaldelete: false
    })

    this.refreshdata();

    })
    .catch(err => {
      alert("Erreur :" + errocode + ", "+err)
      this.setState({ tokenuser: err});
  });  

    document.title = "Liste des QCM";

}


async refreshdata(){
  
  const responseqcmlist = await fetch('https://www.nannylou.com/mobilewbs/qcm/Selectallqcm.php');
  const dataqcm = await responseqcmlist.json();

  if(JSON.stringify(dataqcm).replace(/\"/g, "") !== 'NOT'){
    this.setState({ listqcm: dataqcm})
  }else{
    this.setState({ listqcm: []})
  }

  document.title = "Liste des QCM";

}

selectusertype(e) {
  this.setState({ usertype: e.target.value });
}

selectsociety(e) {
  this.setState({ societe: e.target.value });
}



onChange = (editorState) => {
  this.setState({
    editorState
  });
  
}

testtoken(){
      
  let token = ls.get('token');

  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    ls.clear()
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    ls.clear()
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

}

  
  render() {  

          return (
              
                <div className="App" >
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/Login"/>
                              

                            :

                            null

                    }
                    
                   

                  {this.state.modaldelete === true ?

                  <Modal

                  show={this.state.modaldelete}
                  onHide={ () => this.setState({modaldelete:false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                                
                    <div className="d-flex flex-row justify-content-center">
                                      
                        <div className="d-flex flex-column" style={{margin:"20px"}}>
                            
                            <div className="d-flex flex-column">
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>Voulez-vous supprimer &nbsp;</p>
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.prenoms} {this.state.nom}?</p>
                                
                                <form>

                                <div className='row text-white d-flex align-items-center'>
                                            
                                      <Button className="btn"style={{ margin:"20px", width:'150px', borderRadius:'25px'}} onClick={ () => this.setState({modaldelete:false})} variant="primary">Fermer</Button>
                                      <Button className="btn" style={{ margin:"10px",right: 0, width:'150px', borderRadius:'25px'}} onClick={() => this.deleteqcm()} variant="danger">Supprimer</Button>

                                </div>
                                
                              </form>
                            </div>
                            
                        </div>

                    </div>    

                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }
                 
                  <div className='centrervue' style={{ paddingTop:'100px'}}>

                  {this.state.listentreprise.length>0 ?
                    
                    
                        <Table>
                          <Thead>
                            <Tr>
                              
                              <Td >Date d'inscription</Td>
                              <Td >Identité</Td>
                              <Td >Contact</Td>
                              <Td >Documents</Td>
                              <Td >Type de garde</Td>
                              <Td >Type de contrat de garde</Td>
  
                              {/*<TableCell >Supprimer</TableCell>*/}

                            </Tr>
                          </Thead>
                          <Tbody>

                            {this.state.listentreprise.map((row) => (

                              <Tr key={row.identreprise} className="tableborder">   

                                <Td >{parseInt(new Date(Date.parse(row.dateinscriptionentreprise.replace(/[-]/g, '/'))).getDate()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateinscriptionentreprise.replace(/[-]/g, '/'))).getDate())}</label>:parseInt(new Date(Date.parse(row.dateinscriptionentreprise.replace(/[-]/g, '/'))).getDate())} - {parseInt(new Date(Date.parse(row.dateinscriptionentreprise.replace(/[-]/g, '/'))).getMonth())+1 < 10 ? <label>0{parseInt(new Date(Date.parse(row.dateinscriptionentreprise.replace(/[-]/g, '/'))).getMonth())+1}</label>:parseInt(new Date(Date.parse(row.dateinscriptionentreprise.replace(/[-]/g, '/'))).getMonth())+1} - {new Date(Date.parse(row.dateinscriptionentreprise.replace(/[-]/g, '/'))).getFullYear()}</Td>
                                <Td>{row.nomentreprise} <br/> SIRET : {row.numerosiret} <br/>{row.codepostal} {row.ville} <br/>{row.adresse}<br/></Td>
                                <Td><a style={{textAlign:'left', textDecoration:'none'}} href={"mailto:" + row.email}>Mail: {row.email}</a> <br/> <a style={{textAlign:'left', textDecoration:'none'}} href={"tel:" + row.telephone}>Tél: {row.telephone}</a></Td>
                                <Td>
                                  {row.urssaf != '' ? <a href={this.state.liendocument + row.urssaf} target="_blank">URSSAF<br/></a> : null}
                                  {row.fichedeposte != '' ? <a href={this.state.liendocument + row.fichedeposte} target="_blank">FICHE DE POSTE<br/></a> : null}
                                  {row.kbis != '' ? <a href={this.state.liendocument + row.kbis} target="_blank">KBIS<br/></a> : null}
                                </Td> 
                                <Td>{row.typegardeenfant}</Td>   
                                <Td>{row.typecontratgarde}</Td>                     
                                {/*<TableCell ><button type="submit" className="btn btn-danger btn-circle btn-xl" style={{borderRadius:'25px'}} onClick={() => this.showdeleteqcm(row.idutilisateur,row.nomnounou,row.prenomsnounou)}><span><DeleteIcon /></span></button></TableCell>*/}
                    
                              </Tr>

                            ))}

                          </Tbody>
                        </Table>
                   
                
                    :

                    null

                  }
                  
                  </div> 

                </div>
                
          );
        }
    
}

export default Listeentreprise;
