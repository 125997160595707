/*eslint-disable no-unused-vars, ,no-console */
import React,  {Alert,Component} from 'react';
import './App.css';
import Menu from './components/Menu';
import Accueil from './components/Accueil';
import Quisommesnous from './components/Quisommesnous';
import Commentcamarche from './components/Commentcamarche';
import CGU from './components/CGU';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Parents from './components/Parents';
import Entreprises from './components/Entreprises';
import Nannies from './components/Nannies';
import Inscription from './components/Inscription';
import Login from './components/Login';
import Promotion from './components/Promotion';
import Profil from './components/Profil';
import TESTQCM from './components/TESTQCM';
import Paiementresult from './components/Paiementresult';
import QCMADMIN from './components/QCMADMIN';
import Listenounou from './components/Listenounou';
import Listeparent from './components/Listeparent';
import Listepaiement from './components/Listepaiement';
import Listecse from './components/Listecse';
import Carte from './components/Carte';
import Listeentreprise from './components/Listeentreprise';
import Listecommande from './components/Listecommande';
import Cse from './components/Cse';
import Dev from './components/Dev';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import { isBrowser, isMobile} from "react-device-detect";
import reactRouterToArray from 'react-router-to-array';
import Zoom from 'react-reveal/Zoom';

const allroutes = reactRouterToArray(

      <Route>
            <Route  exact={true} path="/" component={Accueil} handler={Accueil}></Route>
            <Route  exact={true} path="/Accueil" component={Accueil} handler={Accueil}></Route>
            <Route  exact={true} path="/Quisommesnous" component={Quisommesnous} handler={Accueil}></Route>
            <Route  exact={true} path="/Parents" component={Parents} handler={Parents}></Route>
            <Route  exact={true} path="/Entreprises" component={Entreprises} handler={Entreprises} ></Route>
            <Route  exact={true} path="/Nannies" component={Nannies} handler={Nannies}></Route>
            <Route  exact={true} path="/CGU" component={CGU} handler={CGU} ></Route>
            <Route  exact={true} path="/Contact" component={Contact} handler={Contact} ></Route>
            <Route  exact={true} path="/Inscription" component={Inscription} handler={Inscription} ></Route>
            <Route  exact={true} path="/Inscription/:referralid" component={Inscription} handler={Inscription} ></Route>
            <Route  exact={true} path="/QUIZ/:mailuser" component={TESTQCM} handler={TESTQCM} ></Route>
            <Route  exact={true} path="/QCMADMIN" component={QCMADMIN} handler={QCMADMIN} ></Route>
            <Route  exact={true} path="/Login" component={Login} handler={Login} ></Route>
            <Route  exact={true} path="/Promotion" component={Promotion} handler={Promotion} ></Route>
            <Route  exact={true} path="/Profil" component={Profil} handler={Profil} ></Route>
            <Route  exact={true} path="/Paiementresult/:datapaiement" component={Paiementresult} handler={Paiementresult} ></Route>
            <Route  exact={true} path="/Listenounou" component={Listenounou} handler={Listenounou} ></Route>
            <Route  exact={true} path="/Listeparent" component={Listeparent} handler={Listeparent} ></Route>
            <Route  exact={true} path="/Listeentreprise" component={Listeentreprise} handler={Listeentreprise} ></Route>
            <Route  exact={true} path="/Listepaiement" component={Listepaiement} handler={Listepaiement} ></Route>
            <Route  exact={true} path="/Listecse" component={Listecse} handler={Listecse} ></Route>
            <Route  exact={true} path="/Listecommande" component={Listecommande} handler={Listecommande} ></Route>
            <Route  exact={true} path="/Carte" component={Carte} handler={Carte} ></Route>
            <Route  exact={true} path="/Cse" component={Cse} handler={Cse} ></Route>
            <Route  exact={true} path="/Cse/:cseid" component={Cse} handler={Cse} ></Route>
            <Route  exact={true} path="/Dev" component={Dev} handler={Dev} ></Route>
      </Route>

);


class App extends Component{
    
  constructor(props) {
  super(props);
  this.state = {
        show: false,
        setShow: false,
        tokenvalue:'',
        reload : false,
        username:'',
        tokenid:'',
        notfound : false,
      };
      
}

componentDidMount() {

      window.addEventListener("blur", this.newroute());

}

newroute(){
      
      if(allroutes.includes(window.location.pathname) === false ){
            this.setState({ notfound: true})
      }


      

      //alert(window.location.pathname);
      //alert(JSON.stringify(this.state.routepaths))

}

componentWillMount() {

      
}


render() {

  return (

      <div className='noselectdata'>

            <Router>
      
            
            <div style={{position:"fixed", width:"100%", top:0, zIndex: '1000'}}>

                  <Menu /> 
            
            </div>      
                  
                        <Switch>
                              
                              <Route  path="/" exact component={Accueil}></Route>
                              <Route  exact={true} path="/Accueil" component={Accueil} handler={Accueil}></Route>
                              <Route  exact={true} path="/Quisommesnous" component={Quisommesnous} handler={Accueil}></Route>
                              <Route  exact={true} path="/Parents" component={Parents} handler={Parents}></Route>
                              <Route  exact={true} path="/Entreprises" component={Entreprises} handler={Entreprises} ></Route>
                              <Route  exact={true} path="/Nannies" component={Nannies} handler={Nannies}></Route>
                              <Route  exact={true} path="/CGU" component={CGU} handler={CGU} ></Route>
                              <Route  exact={true} path="/Contact" component={Contact} handler={Contact} ></Route>
                              <Route  exact={true} path="/Inscription" component={Inscription} handler={Inscription} ></Route>
                              <Route  exact={true} path="/Inscription/:referralid" component={Inscription} handler={Inscription} ></Route>
                              <Route  exact={true} path="/QUIZ/:mailuser" component={TESTQCM} handler={TESTQCM} ></Route>
                              <Route  exact={true} path="/QCMADMIN" component={QCMADMIN} handler={QCMADMIN} ></Route>
                              <Route  exact={true} path="/Login" component={Login} handler={Login} ></Route>
                              <Route  exact={true} path="/Promotion" component={Promotion} handler={Promotion} ></Route>
                              <Route  exact={true} path="/Profil" component={Profil} handler={Profil} ></Route>
                              <Route  exact={true} path="/Paiementresult/:datapaiement" component={Paiementresult} handler={Paiementresult} ></Route>
                              <Route  exact={true} path="/Listenounou" component={Listenounou} handler={Listenounou} ></Route>
                              <Route  exact={true} path="/Listeparent" component={Listeparent} handler={Listeparent} ></Route>
                              <Route  exact={true} path="/Listeentreprise" component={Listeentreprise} handler={Listeentreprise} ></Route>
                              <Route  exact={true} path="/Listepaiement" component={Listepaiement} handler={Listepaiement} ></Route>
                              <Route  exact={true} path="/Listecse" component={Listecse} handler={Listecse} ></Route>
                              <Route  exact={true} path="/Listecommande" component={Listecommande} handler={Listecommande} ></Route>
                              <Route  exact={true} path="/Carte" component={Carte} handler={Carte} ></Route>
                              <Route  exact={true} path="/Cse" component={Cse} handler={Cse} ></Route>
                              <Route  exact={true} path="/Cse/:cseid" component={Cse} handler={Cse} ></Route>
                              <Route  exact={true} path="/Dev" component={Dev} handler={Dev} ></Route>
                              
                                    
                        </Switch>

                  </Router>


                  {this.state.notfound == true?

                        <div className ="div404" style={{backgroundImage: "url(" + process.env.PUBLIC_URL + './images/404.png' + ")", backgroundPosition: 'center',  backgroundSize: isBrowser ? 'cover': 'cover',  backgroundRepeat: 'no-repeat', width:'100%', height: isBrowser? '65vh':'65vh'}}>
                              
                              <Zoom left>
                                    <p style={{fontSize: isBrowser? "5rem":"2.5rem", marginTop:"100px"}}>La page est introuvable.</p>
                              </Zoom>
        
                        </div>
                        

                        :

                        null
                        
                  }

            <Footer/>



      </div>

            

            );  
  
      }

}

export default App;

