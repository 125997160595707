import './App.css';
import './moncss.css';
import React,  {Alert,Component} from 'react';


class Commentcamarche extends Component{
    
    constructor(props) {
    super(props);
        
  }
    
    componentDidMount() {
        
        
    };
   
    
  render() {

          return (
              
                <div className="App">
                    
                    
                <h1>test</h1>
                    

                </div>
          );
        }
    
}

export default Commentcamarche;
