import './App.css';
import './moncss.css';
import React,  {Component} from 'react';
import {Button,Form,Container,Row,Col} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'
import Fade from 'react-reveal/Fade';
import axios from 'axios';
import { BrowserView, MobileView, isBrowser} from "react-device-detect";
import queryString from 'query-string';
import DatePicker, { registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from 'date-fns/locale/fr';
import Loader from "react-js-loader";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input'

var errocode = 0;

var dateselect = new Date();

class Nannies extends Component{
    
    constructor(props) {
    super(props);

    this.state = {
      paypalpay:false,
      tarif:0.00,
      listtarif:[],
      iduser:0,
      nom: '',
      prenoms: '',
      nationalite:'',
      mail: '',
      motdepasse: '',
      societe: 0,
      telephone: '',
      poste: '',
      diplome: 0,
      datedenaissance :'',
      reference: 0,
      experience: '',
      permis: 0,
      vehicule: 0,
      typedegarde:0,
      heuregarde:'',
      heuregardede:0,
      heuregardea:0,
      ismodalinscription:false,
      idtypeuser: 2,
      errorform: false,
      errormessage:'',
      listtypegarde:[],
      listconnaissance:[],
      connaisancennl:0,
      selectedFileCV: null,
      selectedFileCVitale:null,
      selectedFilediplome: null,
      selectedFilepermis: null,
      selectedFilecj: null,
      selectedFilePITJ:null,
      inscriptioneffectue : false,
      loadershow:false,
      lienimage: process.env.PUBLIC_URL +'images/',
      offre:0,
      newsletters:0,
      validationformulaire:0,
      cgu:0,
      infosup:'',
    };

    this.selectusertypegarde = this.selectusertypegarde.bind(this);
    this.selectconnaissance = this.selectconnaissance.bind(this);
    this.phonenumber = this.phonenumber.bind(this);
        
  }
    
  async componentDidMount() {
      
    const response = await fetch('https://www.nannylou.com/mobilewbs/tarif/selectalltarif.php');
    const data = await response.json();
      this.setState({ listtarif: data[0]})

      document.title = "Pour les Nannies"

      registerLocale('fr', fr)

      const responsetypegarde = await fetch('https://www.nannylou.com/mobilewbs/garde/selectallgarde.php');
      const datatypegarde = await responsetypegarde.json();
      this.setState({ listtypegarde: datatypegarde})

      const responselistconnaissanse = await fetch('https://www.nannylou.com/mobilewbs/connaissance/selectallconnaissance.php');
      const datalistconnaissanse = await responselistconnaissanse.json();
      this.setState({ listconnaissance: datalistconnaissanse})

    };

    phonenumber() {
      var x = document.getElementById("phonenumber").value;
      this.setState({ telephone: x})
    }

    selectusertypegarde(e) {
      this.setState({ typedegarde: e.target.value });
    }

    selectconnaissance(e) {
      this.setState({ connaisancennl: e.target.value });
    }
  
    paiement = (prixnet) => { 
      this.setState({ tarif: prixnet,paypalpay:true}) 
      //alert("Paiement effectué à la somme totale de " + prixnet + "€.");
    }

    showmodalinscription(){
      this.setState({ ismodalinscription:true})  
    }

    selectdate = (datevalue) =>{

      dateselect = new Date(datevalue);

      var moisdenaissance = '';

      if(parseInt(datevalue.getMonth())<9){
        moisdenaissance = '0' + (parseInt(datevalue.getMonth()) + parseInt(1))
      }else{
        moisdenaissance = (parseInt(datevalue.getMonth()) + 1)
      }

      var jourdenaissance = '';
      if(parseInt(datevalue.getDate())<9){
        jourdenaissance = '0' + (parseInt(datevalue.getDate()))
      }else{
        jourdenaissance = (parseInt(datevalue.getDate()))
      }
      var datenaissance = datevalue.getFullYear()  + '-' + moisdenaissance + '-' + jourdenaissance ;
      

        //alert('THIS new date : ' + datenaissance)
        this.setState({ datedenaissance: datenaissance})

        //alert(moisdenaissance)
    }

    componentWillMount() {
      window.scrollTo(0, 0);
    }

    inscription () {

        if (this.state.mail == "" ) {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(this.state.mail)) {

              //this.setState({ errorform: true, errormessage: 'Email invalide. Veuillez saisir une adresse e-mail valide s\'il vous plait.' });
                alert('Email invalide. Veuillez saisir une adresse e-mail valide s\'il vous plait.')
                return;

            }
            
        }

        if (this.state.nom == "" ) {

          alert('Veuillez saisir votre nom.' );
                            
                return;

        }

        if (this.state.prenoms == "" ) {

          alert('Veuillez saisir votre prénoms.');
                            
                return;

        }

        {/* 

        if (this.state.nationalite == "" ) {

          alert('Veuillez saisir votre nationalité ou votre titre de séjour français.');
                            
                return;

        }
        */}
                
        if (this.state.motdepasse == "") {

          alert('Mot de passe requis. Veuillez saisir votre mot de passe.');
                            
                return;

        }

        if(isValidPhoneNumber(this.state.telephone) === false){
          alert('Veuillez rectifier votre numéro de téléphone.');
                            
                return;
        }

        if (this.state.motdepasse.length < 8 ) {

          alert('Le mot de passe devrait être au moins 8 caractères.');
                            
                return;

        }

        if (this.state.datedenaissance == "") {

          alert('Date de naissance invalide.');
                            
                return;

        }

        if (this.state.telephone == "") {

          alert('Veuillez saisir votre numero de téléphone.');
                            
                return;

        }

        if (this.state.adresse == "") {

          alert('Veuillez saisir votre adresse.' );
                            
                return;

        }

        if (this.state.codepostale == "") {

          alert('Veuillez saisir votre code postale.');
                            
                return;

        }

        if (this.state.ville == "") {

          alert('Veuillez saisir votre ville.' );
                            
                return;

        }

        if (this.state.experience == "") {

          alert('Veuillez saisir votre expérience.' );
                            
                return;

        }

        if (this.state.typedegarde == "") {

          alert('Veuillez saisir votre temps de garde.');
                            
                return;

        }

        if (this.state.heuregarde.length==0  && this.state.idtypeuser === '2')  {

          this.setState({ errorform: true, errormessage: 'Veuillez saisir l\'heure de garde.' });
                            
                return;
  
        }

        if (this.state.experience == "" && this.state.idtypeuser === '2') {

          alert('Veuillez saisir votre expérience.');
                            
                return;

        }

        if (this.state.typedegarde == "" && this.state.idtypeuser === '2') {

          alert('Veuillez saisir votre temps de garde.');
                            
                return;

        }

        if (this.state.heuregarde.length==0  && this.state.idtypeuser === '2')  {

          this.setState({ errorform: true, errormessage: 'Veuillez saisir l\'heure de garde.' });
                            
                return;
  
        }

        {/*
        if (this.state.heuregardede == 0 && this.state.idtypeuser === '2') {

          this.setState({ errorform: true, errormessage: 'Veuillez saisir votre heure de début de garde.' });
                            
                return;
  
        }
  
        if (this.state.heuregardea == 0 && this.state.idtypeuser === '2') {
  
          this.setState({ errorform: true, errormessage: 'Veuillez saisir votre heure de fin de garde.' });
                            
                return;
  
        }
        */}

        if (this.state.connaisancennl == 0) {

          alert('Veuillez sélectionner parmi la liste de réseau dont vous nous avez connu.');
                            
                return;

        }

        if(Number(this.state.validationformulaire) == 0){
          alert('Veuillez accepter la validation du formulaire.');              
                return;
        }
  
        if(Number(this.state.newsletters) == 0){
          alert('Veuillez accepter la récéption des newsletters.');              
                return;
        }

        if(Number(this.state.cgu) == 0){
          alert('Veuillez accepter les conditions générales d\'utilisation.');              
                return;
        }

        {/*

        if (parseInt(this.state.heuregardede) < 1 || parseInt(this.state.heuregardede) > 23) {

          this.setState({ errorform: true, errormessage: 'Veuillez mettre l\'heure de début de garde entre 1h à 23h.' });
                            
                return;
  
        }
  
        if (parseInt(this.state.heuregardea) < 1 || parseInt(this.state.heuregardea) > 23) {
  
          this.setState({ errorform: true, errormessage: 'Veuillez mettre l\'heure de fin de garde entre 1h à 23h.' });
                            
                return;
  
        }
  
        if (parseInt(this.state.heuregardede) > parseInt(this.state.heuregardea)) {
  
          this.setState({ errorform: true, errormessage: 'L\'heure de début devrait être inférieure à l\'heure de fin de garde.' });
                            
                return;
  
        }

        */}

        var dateinscription = new Date();

        var moisinscription = '';

        if(parseInt(dateinscription.getMonth())<9){
          moisinscription = '0' + (parseInt(dateinscription.getMonth()) + parseInt(1))
        }else{
          moisinscription = parseInt(dateinscription.getMonth()) + parseInt(1)
        }
  
        var jourinscription = '';
        if(parseInt(dateinscription.getDate())<9){
          jourinscription = '0' + (parseInt(dateinscription.getDate()) + parseInt(1))
        }else{
          jourinscription= parseInt(dateinscription.getDate())
        }
        var dateinscriptionsend = dateinscription.getFullYear()  + '-' + moisinscription + '-' + jourinscription ;

        this.verifiecoordonnee();

  }; 

  async verifiecoordonnee(){

    var adressecomplet = 'https://api-adresse.data.gouv.fr/search/?q=' + this.state.codepostale.replaceAll(' ', '+') + this.state.ville.replaceAll(' ', '+') + this.state.adresse.replaceAll(' ', '+') + '&limit=1&autocomplete=0';

      const responsecoordonnee= await fetch(adressecomplet);
      const datalatlong = await responsecoordonnee.json();

      if(datalatlong.features.length === 0){

        this.setState({ loadershow: false});

        alert("Veuillez rectifier votre adresse, code postale et ville car il n'est pas reconnu.")

        return;

      }else{

        var latlong = JSON.stringify(datalatlong.features[0].geometry.coordinates).replace('[', '').replace(']', '');

        this.inscriptionsend(latlong);

      }

  }

  inscriptionsend (latlong) {

    var latlongsplit = latlong.split(',');

    var idref = 0;
    
    if(!this.state.idreferral || this.state.idreferral == '0'){
        idref = 0;
    }else{
        idref = parseInt(this.state.idreferral);
    }      

    var dateinscription = new Date();

    var moisinscription = '';

    if(parseInt(dateinscription.getMonth())<9){
      moisinscription = '0' + (parseInt(dateinscription.getMonth()) + parseInt(1))
    }else{
      moisinscription = parseInt(dateinscription.getMonth()) + parseInt(1)
    }

    var jourinscription = '';
    if(parseInt(dateinscription.getDate())<9){
      jourinscription = '0' + (parseInt(dateinscription.getDate()) + parseInt(1))
    }else{
      jourinscription= parseInt(dateinscription.getDate())
    }
    var dateinscriptionsend = dateinscription.getFullYear()  + '-' + moisinscription + '-' + jourinscription ;

    if (parseInt(this.state.idtypeuser) == 2) {

            fetch(`https://www.nannylou.com/mobilewbs/nounou/ajoutnounou.php`,{                  
        
              method:'post',
              headers: {'Content-Type':'application/x-www-form-urlencoded'},
              body: queryString.stringify({

                Dateinscription : dateinscriptionsend,
                Nomnounou: this.state.nom,
                Prenomsnounou: this.state.prenoms,
                Nationalite: this.state.nationalite,
                Telephone:this.state.telephone,
                Email:this.state.mail,
                Adresse:this.state.adresse,
                Latitude:latlongsplit[1],
                Longitude:latlongsplit[0],
                Codepostale : this.state.codepostale,
                Ville : this.state.ville,
                Datedenaissance : this.state.datedenaissance,
                Diplome : Number(this.state.diplome),
                Reference: Number(this.state.reference),
                Experience: this.state.experience,
                Permis: Number(this.state.permis),
                Vehicule:Number(this.state.vehicule),
                Garde: this.state.typedegarde,
                Heurede: this.state.heuregardede,
                Heurea: this.state.heuregardea,
                Heuregarde: this.state.heuregarde,
                Motdepassenounou:this.state.motdepasse,
                connu: this.state.connaisancennl,
                Idreferral : idref,
                offre:Number(this.state.offre),
                newsletters:Number(this.state.newsletters),
                infosup:this.state.infosup,
              })

          })

          .then(res => {

            errocode = res.status;
      
            if (!res.ok) {
                
                return res.json().then(json => { throw json; });
      
            }
      
            return res.json();
      
          })
          .then(data => {

            if(JSON.stringify(data).replace(/\"/g, "") === 'Not'){
              this.setState({ errorform: true, errormessage: 'Erreur de données.' });

              return;
            }

            if(JSON.stringify(data).replace(/\"/g, "") === 'Userfound'){
              this.setState({ errorform: true, errormessage: 'Utilisateur déjà enregistré.',loadershow:false });

              return;
            }
            
            this.setState({
              errorform: false,
              errormessage:'',
              usertype:0,
              loadershow:true,
              iduser: parseInt(JSON.stringify(data).replace(/\"/g, "")),
              nom: '',
              prenoms: '',
              nationalite: '',
              mail: '',
              motdepasse: '',
              societe: 0,
              telephone: '',
              poste: '',
              adresse :'',
              codepostale : '',
              ville : '',
              permis:0,
              diplome:0,
              experience:'',
              reference:0,
              vehicule:0,
              idtypeuser : 2,
              typedegarde:'',
              heuregarde:'',
              heuregardede:0,
              heuregardea:0,
              datedenaissance :dateselect,
              inscriptioneffectue : true,
              cachelistenfant:[],
              listenfant:[],
              inscriptioneffectue : false,
            });      

            let iduser = parseInt(JSON.stringify(data).replace(/\"/g, ""));
            
            if(this.state.selectedFilePITJ !== null){

              // Create an object of formData 
              const formDatapitj = new FormData(); 
             
              // Update the formData object 
              
              formDatapitj.append( 
                "images", 
                this.state.selectedFilePITJ, 
                this.state.selectedFilePITJ.name,
              ); 
          
              formDatapitj.append("iduser",iduser);
             
              // Send formData object 
              axios.post("https://www.nannylou.com/mobilewbs/nounou/ajoutpitj.php", formDatapitj)
              .then(res => {
                
                if(JSON.stringify(res.data).replace(/\"/g, "") !== 'OK'){
                  alert(JSON.stringify(res.data));
                  return;
                }else{
                  
                  this.setState({
                    selectedFilePITJ: null,
                  });
          
                }
                
              })

            }

            
            if(this.state.selectedFileCVitale !== null){

              // Create an object of formData 
              const formDatacvitale = new FormData(); 
            
              // Update the formData object 
              formDatacvitale.append( 
                "images", 
                this.state.selectedFileCVitale, 
                this.state.selectedFileCVitale.name,
              ); 

              formDatacvitale.append("iduser",iduser);
            
              // Send formData object 
              axios.post("https://www.nannylou.com/mobilewbs/nounou/ajoutcvitale.php", formDatacvitale)
              .then(res => {
                
                if(JSON.stringify(res.data).replace(/\"/g, "") !== 'OK'){
                  alert(JSON.stringify(res.data));
                  return;
                }else{

                  this.setState({
                    selectedFileCVitale: null,
                  });

                }
                
              })

              //this.ajoutercvitale();

            }

            if(this.state.selectedFileCV !== null){

              // Create an object of formData 
              const formDatacv = new FormData(); 
            
              // Update the formData object 
              formDatacv.append( 
                "images", 
                this.state.selectedFileCV, 
                this.state.selectedFileCV.name,
              ); 

              

              formDatacv.append("iduser",iduser);
            
              // Send formData object 
              axios.post("https://www.nannylou.com/mobilewbs/nounou/ajoutcv.php", formDatacv)
              .then(res => {

                if(JSON.stringify(res.data).replace(/\"/g, "") !== 'OK'){
                  alert(JSON.stringify(res.data));
                  return;
                }else{

                  this.setState({
                    selectedFileCV: null,
                  });

                }
                
              })


            }
    
            if(this.state.selectedFilediplome !== null){

              // Create an object of formData 
              const formData = new FormData(); 
            
              // Update the formData object 
              formData.append( 
                "images", 
                this.state.selectedFilediplome, 
                this.state.selectedFilediplome.name,
              ); 

              formData.append("iduser",iduser);
            
              // Send formData object 
              axios.post("https://www.nannylou.com/mobilewbs/nounou/ajoutdiplome.php", formData)
              .then(res => {
            
                if(JSON.stringify(res.data).replace(/\"/g, "") !== 'OK'){
                  alert(JSON.stringify(res.data));
                  return;
                }else{

                  this.setState({
                    selectedFilediplome: null,
                  });

                }

              })

            }
    
            if(this.state.selectedFilepermis !== null){

              // Create an object of formData 
              const formData = new FormData(); 
            
              // Update the formData object 
              formData.append( 
                "images", 
                this.state.selectedFilepermis, 
                this.state.selectedFilepermis.name,
              ); 

              formData.append("iduser",iduser);
            
              // Send formData object 
              axios.post("https://www.nannylou.com/mobilewbs/nounou/ajoutpermis.php", formData)
              .then(res => {

                if(JSON.stringify(res.data).replace(/\"/g, "") !== 'OK'){
                  alert(JSON.stringify(res.data));
                  return;
                }else{

                  this.setState({
                    selectedFilepermis: null,
                  });

                }

              })

            }
    
            if(this.state.selectedFilecj !== null){

               // Create an object of formData 
              const formData = new FormData(); 
            
              // Update the formData object 
              formData.append( 
                "images", 
                this.state.selectedFilecj, 
                this.state.selectedFilecj.name,
              ); 

              formData.append("iduser",iduser);
            
              // Send formData object 
              axios.post("https://www.nannylou.com/mobilewbs/nounou/ajoutcj.php", formData)
              .then(res => {

                if(JSON.stringify(res.data).replace(/\"/g, "") !== 'OK'){
                  alert(JSON.stringify(res.data));
                  return;
                }else{
                  this.setState({
                    selectedFilecj: null,
                  });

                }

              })

            }

            this.fermermodalinscription();

          })

          .catch(err => {
            alert("Erreur :" + errocode + ", "+err)
            this.setState({ tokenuser: err});
          }); 

    }

  }

  onFileChangecv = event => { 

    if(event.target.files[0] !== null){

      this.setState({
        selectedFileCV: event.target.files[0],
      });

    }

  }; 

  ajoutercv = () => { 

    if(this.state.selectedFileCV === null ){

        this.setState({
          loadershow: true,
          textloading:'Veuillez séléctionner votre CV.'
        });
      alert('Veuillez séléctionner votre CV.');
      return;
    
    }

    let iduserupload = this.state.iduser;

    // Create an object of formData 
    const formData = new FormData(); 
   
    // Update the formData object 
    formData.append( 
      "images", 
      this.state.selectedFileCV, 
      this.state.selectedFileCV.name,
    ); 

    formData.append("iduser",iduserupload);
   
    // Send formData object 
    axios.post("https://www.nannylou.com/mobilewbs/nounou/ajoutcv.php", formData)
    .then(res => {
      
      if(JSON.stringify(res.data).replace(/\"/g, "") !== 'OK'){
        alert(JSON.stringify(res.data));
        return;
      }else{

        this.setState({
          selectedFileCV: null,
        });

        this.fermermodalinscription();

      }
      
  })

}

  onFileChangediplome = event => { 

    if(event.target.files[0] !== null){

      this.setState({
        selectedFilediplome: event.target.files[0],
      });

    }

  }; 

  ajouterdiplome = () => { 

    if(this.state.selectedFilediplome === null ){

        this.setState({
          loadershow: true,
          textloading:'Veuillez séléctionner votre diplome.'
        });
      alert('Veuillez séléctionner votre diplome.');
      return;
    
    }

    let iduserupload = this.state.iduser;

    // Create an object of formData 
    const formData = new FormData(); 
   
    // Update the formData object 
    formData.append( 
      "images", 
      this.state.selectedFilediplome, 
      this.state.selectedFilediplome.name,
    ); 

    formData.append("iduser",iduserupload);
   
    // Send formData object 
    axios.post("https://www.nannylou.com/mobilewbs/nounou/ajoutdiplome.php", formData)
    .then(res => {
  
      if(JSON.stringify(res.data).replace(/\"/g, "") !== 'OK'){
        alert(JSON.stringify(res.data));
        return;
      }else{

        this.setState({
          selectedFilediplome: null,
        });

        this.fermermodalinscription();

      }

  })

}

  onFileChangepermis = event => { 

    if(event.target.files[0] !== null){

      this.setState({
        selectedFilepermis: event.target.files[0],
      });

    }

  }; 

  ajouterpermis = () => { 

    if(this.state.selectedFilepermis === null ){

        this.setState({
          loadershow: true,
          textloading:'Veuillez séléctionner votre permis.'
        });
      alert('Veuillez séléctionner votre permis.');
      return;
    
    }

    let iduserupload = this.state.iduser;

    // Create an object of formData 
    const formData = new FormData(); 
   
    // Update the formData object 
    formData.append( 
      "images", 
      this.state.selectedFilepermis, 
      this.state.selectedFilepermis.name,
    ); 

    formData.append("iduser",iduserupload);
   
    // Send formData object 
    axios.post("https://www.nannylou.com/mobilewbs/nounou/ajoutpermis.php", formData)
    .then(res => {

      if(JSON.stringify(res.data).replace(/\"/g, "") !== 'OK'){
        alert(JSON.stringify(res.data));
        return;
      }else{

        this.setState({
          selectedFilepermis: null,
        });

        this.fermermodalinscription();

      }

  })

}

onFileChangecj= event => { 

  if(event.target.files[0] !== null){

    this.setState({
      selectedFilecj: event.target.files[0],
    });

  }

}; 

ajoutercj = () => { 

  if(this.state.selectedFilecj === null ){

      this.setState({
        loadershow: true,
        textloading:'Veuillez séléctionner votre casier judiciaire.'
      });
    alert('Veuillez séléctionner votre casier judiciaire.');
    return;
  
  }

  let iduserupload = this.state.iduser;

  // Create an object of formData 
  const formData = new FormData(); 
 
  // Update the formData object 
  formData.append( 
    "images", 
    this.state.selectedFilecj, 
    this.state.selectedFilecj.name,
  ); 

  formData.append("iduser",iduserupload);
 
  // Send formData object 
  axios.post("https://www.nannylou.com/mobilewbs/nounou/ajoutcj.php", formData)
  .then(res => {

    if(JSON.stringify(res.data).replace(/\"/g, "") !== 'OK'){
      alert(JSON.stringify(res.data));
      return;
    }else{
      this.setState({
        selectedFilecj: null,
      });

      this.fermermodalinscription();

    }

})

}

fermermodalinscription(){

  this.setState({
    loadershow: false,
    signin: true,
    ismodalinscription:false,
  })

}
   
    
  render() {

          return (
              
            <div className="App" style={{paddingTop:isBrowser?"90px":"90px",overFlowX: "hidden", backgroundColor:"#FFF4FB"}}>

              <img src={this.state.lienimage + "nounou-header.png"} style={{width:"100%", borderRadius:"0% 0% -50% -50%"}} className="imagenounouavantage"></img>

              <div className="titlegardenannies" style={{marginTop:"-20px"}}>Pour les nannies</div>

              <div style={{color:"black",fontSize:isBrowser?"32px":"20px", margin:"20px"}}>Vous êtes à la recherche d'une garde d'enfants ?</div>

              <div  style={{color:"#6C6C6C", fontSize: "1.2em",textAlign:"left", marginLeft:"10%", marginRight:"10%", width:"80%"}}>
                  <p>Si vous êtes un(e) baby-sitter, nounou ou assistant(e) maternel(le) passionné(e), expérimenté(e), agréé(e), diplômé(e) et/ou certifié(e) dans le domaine de la petite enfance. Nous vous invitons à vous inscrire sur NannyLou, la plateforme de référence pour les services de garde d'enfants.</p>
                  <p>Grâce à un système de géolocalisation vous contactez les parents qui ont besoin d'une garde à proximité de votre domicile. Tous types de garde vous seront proposées. Vous pourrez gérer vos agendas en fonction de vos disponibilités.</p>
                  <p>De longues gardes régulières de jour comme de nuit sont à votre portée.</p>
              </div>

              <div className="fondblurbleu">

              </div>

              <div className="fondblurrose">

              </div>

              <div className={isBrowser?"cadreavantagenounou":""}>

                      <Fade bottom>
                      {/*<div className="titleparents" style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"20px", textAlign:'center'}}>Vos avantages</div>*/}
                      <div className="avantagesparents">
                          <div className="container">

                        
                          <div className="container" style={{zIndex:9999}}>

                              <div className="row">

                                <div className="col-sm">

                                  <div className="row" style={{marginTop:"40px"}}>
                                    <div>
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                        <div className="soustitleavantagesparents">
                                          <span><CheckCircleIcon style={{color:"#FF97D3", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                        </div>
                                    </div>
                                    <div >
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                        <div className="soustitleavantagesparents" style={{minHeight:isBrowser?"80px":"0px"}}>
                                        <p style={{fontWeight:"500", fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>Positionnez-vous sur les annonces parents.</p>
                                        <span></span><br/>
                                        </div>
                                    </div>
                                  </div>

                                  <div className="row" style={{marginTop:isBrowser?"20px":"0px"}}>
                                    <div>
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                        <div className="soustitleavantagesparents">
                                          <span><CheckCircleIcon style={{color:"#FF97D3", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                        </div>
                                    </div>
                                    <div >
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                        <div className="soustitleavantagesparents" style={{minHeight:isBrowser?"80px":"0px"}}>
                                        <p style={{fontWeight:"500", fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>Travaillez en fonction de votre disponibilité.</p>
                                        </div>
                                    </div>

                                  </div>

                                  <div className="row" style={{marginTop:"20px"}}>
                                    <div>
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                        <div className="soustitleavantagesparents">
                                          <span><CheckCircleIcon style={{color:"#FF97D3", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                        </div>
                                    </div>
                                    <div >
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                        <div className="soustitleavantagesparents" style={{minHeight:isBrowser?"80px":"0px"}}>
                                        <p style={{fontWeight:"500", fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>NannyLou vous trouve des parents.</p>
                                        </div>
                                    </div>

                                  </div>

                                  
                                </div>

                                <div className="col-sm">

                                  <div className="row" style={{marginTop:"40px"}}>
                                    <div>
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                        <div className="soustitleavantagesparents">
                                          <span><CheckCircleIcon style={{color:"#FF97D3", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                        </div>
                                    </div>
                                    <div >
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                        <div className="soustitleavantagesparents" style={{minHeight:isBrowser?"80px":"0px"}}>
                                        <p style={{fontWeight:"500", fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>Gérez votre planning de jour et/ou de nuit.</p>
                                        </div>
                                    </div>

                                  </div>

                                  <div className="row" style={{marginTop:"20px"}}>
                                    <div>
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                        <div className="soustitleavantagesparents">
                                          <span><CheckCircleIcon style={{color:"#FF97D3", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                        </div>
                                    </div>
                                    <div >
                                        {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                        <div className="soustitleavantagesparents" style={{minHeight:isBrowser?"80px":"0px"}}>
                                        <p style={{fontWeight:"500", fontSize:"20px", paddingLeft:"30px", color:"#373535"}}>Échangez avec les autres Nannies sur vos pratiques et astuces.</p>
                                        </div>
                                    </div>

                                  </div>

                                </div>

                              </div>

                          </div>
                          
                          </div>

                      </div>
                      </Fade>

                      </div>

                      <Fade bottom>

                        <div className="tarifsnounoudiv" >

                          <div className='listedata' style={{marginTop:"50px"}}>

                            <Card style={{ width: isBrowser?'50%':"90%", minHeight: isBrowser ? '650px' : '650px', borderRadius:'25px', margin:'50px' }} className="cardlistnounou">                                               
                                                                                          
                            <Card.Body >
                                                    
                                <Card.Title style={{fontSize:"30px", lineHeight:"45px", textAlign:"center", margin:"10px",color:"#7B4EFB"}}>

                                  <div className="container center">
                                    <div className="row">

                                      <div className="col-sm">
                                        <div className='traitvioletgauche'></div>
                                      </div>

                                      <div className="col-sm">
                                        <p>NannyLou</p>
                                      </div>

                                      <div className="col-sm">
                                        <div className='traitvioletdroite'></div>
                                      </div>
                                    </div>
                                  </div>

                                </Card.Title>
                               
                                {/*<p style={{fontSize:"0em", textDecoration:"line-through"}}>.</p>*/}
                                <div className='row text-white d-flex '>

                                    <div className="row" style={{marginLeft: isBrowser ?"5%":"0%",marginRight:isBrowser ?"5%":"0%",marginTop:"20px"}}>
                                      <div>
                                          {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Inscription Offerte</p>
                                          </div>
                                      </div>
                                    </div>

                                    <div className="row" style={{marginLeft:isBrowser ?"5%":"0%",marginRight:isBrowser ?"5%":"0%",marginTop:"20px"}}>
                                      <div>
                                          {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Plateforme d’échanges entre nannies</p>
                                          </div>
                                      </div>
                                    </div>

                                    <div className="row" style={{marginLeft:isBrowser ?"5%":"0%",marginRight:isBrowser ?"5%":"0%",marginTop:"20px"}}>
                                      <div>
                                          {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>2h/garde minimum du domicile des parents</p>
                                          </div>
                                      </div>
                                    </div>

                                    <div className="row" style={{marginLeft:isBrowser ?"5%":"0%",marginRight:isBrowser ?"5%":"0%",marginTop:"20px"}}>
                                      <div>
                                          {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Sécurisation du salaire</p>
                                          </div>
                                      </div>
                                    </div>

                                    <div className="row" style={{marginLeft:isBrowser ?"5%":"0%",marginRight:isBrowser ?"5%":"0%",marginTop:"20px"}}>
                                      <div>
                                          {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Gestion de son planning</p>
                                          </div>
                                      </div>
                                    </div>

                                    <div className="row" style={{marginLeft:isBrowser ?"5%":"0%",marginRight:isBrowser ?"5%":"0%",marginTop:"20px"}}>
                                      <div>
                                          {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Remplacement : Travailler en binôme/trinôme</p>
                                          </div>
                                      </div>
                                    </div>

                                    <div className="row" style={{marginLeft:isBrowser ?"5%":"0%",marginRight:isBrowser ?"5%":"0%",marginTop:"20px"}}>
                                      <div>
                                          {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Aide et présentation profil</p>
                                          </div>
                                      </div>
                                    </div>

                                    <div className="row" style={{marginLeft:isBrowser ?"5%":"0%",marginRight:isBrowser ?"5%":"0%",marginTop:"20px"}}>
                                      <div>
                                          {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Dépôt de son annonce</p>
                                          </div>
                                      </div>
                                    </div>

                                    <div className="row" style={{marginLeft:isBrowser ?"5%":"0%",marginRight:isBrowser ?"5%":"0%",marginTop:"20px"}}>
                                      <div>
                                          {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>01</p>*/}
                                          <div className="soustitleavantagesparents">
                                            <span><CheckCircleOutlineIcon style={{color:"#30B65C", marginRight:'20px', marginTop:"5px",position: "absolute",}}/></span>
                                          </div>
                                      </div>
                                      <div >
                                          {/*<p style={{color:"#FF7E5A", fontWeight:"bold", fontSize:"2.5em"}}>02</p>*/}
                                          <div className="soustitleavantagesparents" >
                                          <p style={{fontWeight:"400", fontSize:"20px", paddingLeft:"30px", color:"#4d4949"}}>Disponibilité : visibilité profil sur la carte</p>
                                          </div>
                                      </div>
                                    </div>

                                    <Button style={{
                                        marginTop:"40px",
                                        background: "#FF7E5A",
                                        border: 0,                           
                                        height: 48,
                                        width:"60%",
                                        borderRadius:'16px',
                                        marginLeft:"20%",
                                        marginRight:"20%",
                                        }} onClick={ () => this.setState({ismodalinscription:true})} >Inscrivez-vous</Button>

                                </div>
                                
                              </Card.Body>

                            </Card>   

                            </div>

                          </div>

                        </Fade>


            <div className='align-items-center' >
            <Fade bottom>
                                
              <div className='align-items-center'>
          
              {/*<p style={{fontSize:"2.5em", color:"#f65ff3", fontWeight:"bold"}}>{this.state.listtarif.tarifnounou} € TTC</p>
                    <p style={{textDecoration:"line-through", color:"#918f8f", fonSize:"1.2em"}}>par mois</p>*/}
                   

                    <Modal
                    fullscreen
                      show={this.state.ismodalinscription}
                      onHide={ () => this.setState({ismodalinscription:false})}
                      dialogClassName="modal-dialog"
                      aria-labelledby="example-custom-modal-styling-title" centered
                      >

                    <Modal.Body>
                                
                                              
                    <div style={{marginRight:"10px"}}>

                      <BrowserView>

                      <Container>
                        <Row>
                          <Col>

                            <div className="form-group" style={{margin:'10px'}}>
                              <label>Nom</label>
                              <input type="text" className="form-control" value={this.state.nom} onChange={(e) => this.setState({ nom: e.target.value})} />
                            </div>

                            <div className="form-group" style={{margin:'10px'}}>
                              <label>Prénom(s)</label>
                              <input type="text" className="form-control" value={this.state.prenoms} onChange={(e) => this.setState({ prenoms: e.target.value})} />
                            </div>

                            <div className="form-group" style={{margin:'10px'}}>
                              <label>Email *</label>
                              <input type="email" className="form-control" aria-describedby="emailHelp" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value})} />
                            </div>   

                            <div className="form-group" style={{margin:'10px'}}>
                              <label>Mot de passe *</label>
                              <input type="password" className="form-control" value={this.state.motdepasse} onChange={(e) => this.setState({ motdepasse: e.target.value})} />
                            </div>

                            <div className="form-group" style={{margin:'10px'}}>
                            <label>Date de naissance * </label>
                            <DatePicker
                              maxDate={new Date()}
                              selected = {dateselect}
                              onChange={(date) => this.selectdate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              locale="fr"
                              dateFormat="dd/MM/yyyy"
                              dropdownMode="select"
                              withPortal
                            />
                            </div>             
    
                            <div className="form-group" style={{margin:'10px'}}>
                              <label>Téléphone</label>
                                <PhoneInput
                                  international
                                  countryCallingCodeEditable={false}
                                  defaultCountry="FR"
                                  value={this.state.telephone}
                                  onChange={this.phonenumber}
                                  id="phonenumber"
                                />
                            </div>

                            <div className="form-group" style={{margin:'10px'}}>
                              <label>Adresse</label>
                              <textarea rows={2} type="text" className="form-control" value={this.state.adresse} onChange={(e) => this.setState({ adresse: e.target.value})} />
                            </div>

                            <div className="form-group" style={{margin:'10px'}}>
                              <label>Code postale</label>
                              <input type="text" className="form-control" value={this.state.codepostale} onChange={(e) => this.setState({ codepostale: e.target.value})} />
                            </div>

                            <div className="form-group champeinput" style={{margin:'10px'}}>
                              <label>Ville</label>
                              <input type="text" className="form-control" value={this.state.ville} onChange={(e) => this.setState({ ville: e.target.value})} />
                            </div>
                          
                          </Col>

                          <Col>


                          {this.state.idtypeuser === 2 ?

                              <div>

                                <div className="form-group" style={{marginLeft:'10px',marginRight:'10px',marginTop:'10px',marginBottom:'10px'}}>
                                  <label>CV</label>
                                  <input type="file" accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg" className="form-control" onChange={this.onFileChangecv} />
                                </div>

                                <div className="form-group" style={{marginLeft:'10px',marginRight:'10px',marginBottom:'10px'}}>
                                  <label>Diplôme</label>
                                  <input type="file" accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg" className="form-control" onChange={this.onFileChangediplome} />
                                
                                  {/*
                                    <Form.Group>
                                      <Form.Check type={"checkbox"}>
                                        <Form.Check.Label>Diplome</Form.Check.Label>
                                          <Form.Check.Input
                                            type={"checkbox"}
                                            defaultChecked={this.state.diplome}
                                            onClick={(e) => {
                                              this.setState({ diplome: e.target.checked });
                                            }}
                                          />
                                      </Form.Check>
                                    </Form.Group>
                                  */}

                                </div>
                                

                                <div className="form-group" style={{margin:'10px'}}>
                                  <label>Expériences</label>
                                  <textarea rows={2} type="text" className="form-control" value={this.state.experience} onChange={(e) => this.setState({ experience: e.target.value})} />
                                </div>

                                <div className="form-group" style={{margin:'10px'}}>
                                  <Form.Group>
                                    <Form.Check type={"checkbox"}>
                                      <Form.Check.Label>Référence</Form.Check.Label>
                                        <Form.Check.Input
                                          type={"checkbox"}
                                          defaultChecked={this.state.reference}
                                          onClick={(e) => {
                                            this.setState({ reference: e.target.checked });
                                          }}
                                        />
                                    </Form.Check>
                                  </Form.Group>
                                </div>

                                <div className="form-group" style={{marginLeft:'10px',marginRight:'10px',marginTop:'25px',marginBottom:'10px'}}>
                                  <label>Permis de conduire</label>
                                  <input type="file" accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg" className="form-control" onChange={this.onFileChangepermis} />
                                </div>
                                {/*
                                  <Form.Group>
                                    <Form.Check type={"checkbox"}>
                                      <Form.Check.Label>Permis</Form.Check.Label>
                                        <Form.Check.Input
                                          type={"checkbox"}
                                          defaultChecked={this.state.permis}
                                          onClick={(e) => {
                                            this.setState({ permis: e.target.checked });
                                          }}
                                        />
                                    </Form.Check>
                                  </Form.Group>
                                  */}
                                

                                <div className="form-group" style={{margin:'10px'}}>
                                  <Form.Group>
                                    <Form.Check type={"checkbox"}>
                                      <Form.Check.Label>Véhicule</Form.Check.Label>
                                        <Form.Check.Input
                                          type={"checkbox"}
                                          defaultChecked={this.state.vehicule}
                                          onClick={(e) => {
                                            this.setState({ vehicule: e.target.checked });
                                          }}
                                        />
                                    </Form.Check>
                                  </Form.Group>
                                </div>

                                <div className="form-group" style={{margin:'10px'}}>
                                  <label >Garde</label>
                                  <div>
                                    <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} value={this.state.typedegarde} onChange={this.selectusertypegarde}>
                                      {this.state.listtypegarde.map((typ) => (
                                        <option value={typ.idgarde}>{typ.typegarde}</option>
                                      ))}
                                    </Form.Select>
                                  </div>
                                </div>

                                <div className="form-group" style={{margin:'10px'}}>
                                  <label>Heure de garde: </label>
                                  <textarea rows={2} type="text" className="form-control" value={this.state.heuregarde} onChange={(e) => this.setState({ heuregarde: e.target.value})} />
                                  {/* 
                                  <div className="container" style={{marginTop:"20px", marginBottom:"20px"}}>
                                    <div className="row">
                                      <div className="col">De</div>
                                        <div className="col"><input type="text" className="form-control" value={this.state.heuregardede} onChange={(e) => this.setState({ heuregardede: e.target.value})} style={{width:"70px",marginTop:"-5px", marginLeft:"-60px"}}/></div>
                                        <div className="col">à</div><div className="col"><input type="text" className="form-control" value={this.state.heuregardea} onChange={(e) => this.setState({ heuregardea: e.target.value})} style={{width:"70px",marginTop:"-5px", marginLeft:"-60px"}}/></div>
                                      </div>
                                    </div>
                                  </div>
                                  */}                                   
                                </div>
                                {/*
                                <div className="form-group" style={{margin:'10px'}}>
                                  <label>Corodonnées géographique:</label>
                                  
                                  <div className="container" style={{marginTop:"20px", marginBottom:"20px"}}>
                                    <div className="row">Latitude <div className="col"><input type="number" className="form-control" value={this.state.heuregardede} onChange={(e) => this.setState({ heuregardede: e.target.value})} style={{marginTop:"-5px"}}/></div>
                                      Longitude <div className="col"><input type="number" className="form-control" value={this.state.heuregardea} onChange={(e) => this.setState({ heuregardea: e.target.value})} style={{marginTop:"-5px"}}/></div>  
                                    </div>
                                  </div>
                                                                    
                                </div>
                                */}

                                <div className="form-group" style={{marginLeft:'10px',marginRight:'10px',marginTop:'10px',marginBottom:'10px'}}>
                                  <label>Casier judiciaire</label>
                                  <input type="file" accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg" className="form-control" onChange={this.onFileChangecj} />
                                  <a className="texte-clignote" href="https://casier-judiciaire.justice.gouv.fr/" target="_blank">Cliquez ici pour faire une demande de casier judiciaire</a>
                                </div>

                                <div className="form-group" style={{margin:'10px'}}>
                                  <label>Informations supplémentaires {parseInt(this.state.idtypeuser) !== 2? " concernant la garde de l'enfant":""}</label>
                                  <textarea rows={2} type="text" className="form-control" value={this.state.infosup} onChange={(e) => this.setState({ infosup: e.target.value})} />
                                </div>

                                <div className="form-group" style={{margin:'10px'}}>
                                  <label >Comment avez-vous connu NannyLou?</label>
                                  <div>
                                    <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} value={this.state.connaisancennl} onChange={this.selectconnaissance}>
                                      {this.state.listconnaissance.map((cns) => (
                                        <option value={cns.idconnaissance}>{cns.nnlreseau}</option>
                                      ))}
                                    </Form.Select>
                                  </div>
                                </div>

                                {parseInt(this.state.idtypeuser) === 2 ?

                                  <div className="form-group" style={{margin:'10px'}}>
                                      <Form.Group>
                                        <Form.Check type={"checkbox"}>
                                          <Form.Check.Label>Je consens à valider ce formulaire afin que ma candidature soit traitée par NannyLou.</Form.Check.Label>
                                            <Form.Check.Input
                                              type={"checkbox"}
                                              defaultChecked={this.state.validationformulaire}
                                              onClick={(e) => {
                                                this.setState({ validationformulaire: e.target.checked });
                                              }}
                                            />
                                        </Form.Check>
                                      </Form.Group>
                                    </div>

                                    :

                                    <div className="form-group" style={{margin:'10px'}}>
                                      <Form.Group>
                                        <Form.Check type={"checkbox"}>
                                          <Form.Check.Label>Je consens à valider ce formulaire afin que mon inscription soit traité par NannyLou.</Form.Check.Label>
                                            <Form.Check.Input
                                              type={"checkbox"}
                                              defaultChecked={this.state.validationformulaire}
                                              onClick={(e) => {
                                                this.setState({ validationformulaire: e.target.checked });
                                              }}
                                            />
                                        </Form.Check>
                                      </Form.Group>
                                    </div>

                                  }

                                  <div className="form-group" style={{margin:'10px'}}>
                                    <Form.Group>
                                      <Form.Check type={"checkbox"}>
                                        <Form.Check.Label>Je consens à recevoir des newsletters et des communications de la part de NannyLou.</Form.Check.Label>
                                          <Form.Check.Input
                                            type={"checkbox"}
                                            defaultChecked={this.state.newsletters}
                                            onClick={(e) => {
                                              this.setState({ newsletters: e.target.checked });
                                            }}
                                          />
                                      </Form.Check>
                                    </Form.Group>
                                  </div>

                                  <div className="form-group" style={{margin:'10px'}}>
                                    <Form.Group>
                                      <Form.Check type={"checkbox"}>
                                        <Form.Check.Label>Je consens à recevoir des offres commerciales de vos partenaires (liés à la petite enfance).</Form.Check.Label>
                                          <Form.Check.Input
                                            type={"checkbox"}
                                            defaultChecked={this.state.offre}
                                            onClick={(e) => {
                                              this.setState({ offre: e.target.checked });
                                            }}
                                          />
                                      </Form.Check>
                                    </Form.Group>
                                  </div>

                                  <div className="form-group" style={{margin:'10px'}}>
                                    <Form.Group>
                                      <Form.Check type={"checkbox"}>
                                        <Form.Check.Label>Je reconnais avoir pris connaissance et j'accepte les conditions générales d'utilisation.</Form.Check.Label>
                                          <Form.Check.Input
                                            type={"checkbox"}
                                            defaultChecked={this.state.cgu}
                                            onClick={(e) => {
                                              this.setState({ cgu: e.target.checked });
                                            }}
                                          />
                                      </Form.Check>
                                    </Form.Group>
                                  </div>

                              </div>

                              :

                              null

                              }    
                          
                          </Col>
                        </Row>
                      </Container>

                      </BrowserView>

                      <MobileView>
                                
                        <div className="form-group" style={{margin:'10px'}}>
                            <label>Nom</label>
                            <input type="text" className="form-control" value={this.state.nom} onChange={(e) => this.setState({ nom: e.target.value})} />
                          </div>

                          <div className="form-group" style={{margin:'10px'}}>
                            <label>Prénom(s)</label>
                            <input type="text" className="form-control" value={this.state.prenoms} onChange={(e) => this.setState({ prenoms: e.target.value})} />
                          </div>

                          <div className="form-group" style={{margin:'10px'}}>
                            <label>Email *</label>
                            <input type="email" className="form-control" aria-describedby="emailHelp" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value})} />
                          </div>   

                          <div className="form-group" style={{margin:'10px'}}>
                            <label>Mot de passe *</label>
                            <input type="password" className="form-control" value={this.state.motdepasse} onChange={(e) => this.setState({ motdepasse: e.target.value})} />
                          </div>

                          {this.state.idtypeuser === 2 ?

                                  <div className="form-group" style={{margin:'10px'}}>
                                  <label>Date de naissance * </label>
                                  <DatePicker
                                    maxDate={new Date()}
                                    selected = {dateselect}
                                    onChange={(date) => this.selectdate(date)}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    locale="fr"
                                    dateFormat="dd/MM/yyyy"
                                    dropdownMode="select"
                                    withPortal
                                  />
                                  </div>             
                            
                                  :

                                  null

                            }         
                        
                          <div className="form-group" style={{margin:'10px'}}>
                            <label>Téléphone</label>
                              <PhoneInput
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="FR"
                                value={this.state.telephone}
                                onChange={this.phonenumber}
                                id="phonenumber"
                              />
                          </div>

                          <div className="form-group" style={{margin:'10px'}}>
                            <label>Adresse</label>
                            <textarea rows={2} type="text" className="form-control" value={this.state.adresse} onChange={(e) => this.setState({ adresse: e.target.value})} />
                          </div>

                          <div className="form-group" style={{margin:'10px'}}>
                            <label>Code postale</label>
                            <input type="text" className="form-control" value={this.state.codepostale} onChange={(e) => this.setState({ codepostale: e.target.value})} />
                          </div>

                          <div className="form-group" style={{margin:'10px'}}>
                            <label>Ville</label>
                            <input type="text" className="form-control" value={this.state.ville} onChange={(e) => this.setState({ ville: e.target.value})} />
                          </div>

                          {this.state.idtypeuser === 2 ?

                                  <div>

                                    <div className="form-group" style={{marginLeft:'10px',marginRight:'10px',marginTop:'10px',marginBottom:'10px'}}>
                                      <label>CV</label>
                                      <input type="file" accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg" className="form-control" onChange={this.onFileChangecv} />
                                    </div>

                                    <div className="form-group" style={{marginLeft:'10px',marginRight:'10px',marginBottom:'10px'}}>
                                      <label>Diplôme</label>
                                      <input type="file" accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg" className="form-control" onChange={this.onFileChangediplome} />
                                    
                                      {/*
                                        <Form.Group>
                                          <Form.Check type={"checkbox"}>
                                            <Form.Check.Label>Diplome</Form.Check.Label>
                                              <Form.Check.Input
                                                type={"checkbox"}
                                                defaultChecked={this.state.diplome}
                                                onClick={(e) => {
                                                  this.setState({ diplome: e.target.checked });
                                                }}
                                              />
                                          </Form.Check>
                                        </Form.Group>
                                      */}

                                    </div>

                                    <div className="form-group" style={{margin:'10px'}}>
                                      <label>Expériences</label>
                                      <textarea rows={2} type="text" className="form-control" value={this.state.experience} onChange={(e) => this.setState({ experience: e.target.value})} />
                                    </div>

                                    <div className="form-group" style={{margin:'10px'}}>
                                      <Form.Group>
                                        <Form.Check type={"checkbox"}>
                                          <Form.Check.Label>Référence</Form.Check.Label>
                                            <Form.Check.Input
                                              type={"checkbox"}
                                              defaultChecked={this.state.reference}
                                              onClick={(e) => {
                                                this.setState({ reference: e.target.checked });
                                              }}
                                            />
                                        </Form.Check>
                                      </Form.Group>
                                    </div>
                                    
                                    <div className="form-group" style={{marginLeft:'10px',marginRight:'10px',marginTop:'25px',marginBottom:'10px'}}>
                                      <label>Permis de conduire</label>
                                      <input type="file" accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg" className="form-control" onChange={this.onFileChangepermis} />
                                    </div>

                                    <div className="form-group" style={{margin:'10px'}}>
                                      <Form.Group>
                                        <Form.Check type={"checkbox"}>
                                          <Form.Check.Label>Véhicule</Form.Check.Label>
                                            <Form.Check.Input
                                              type={"checkbox"}
                                              defaultChecked={this.state.vehicule}
                                              onClick={(e) => {
                                                this.setState({ vehicule: e.target.checked });
                                              }}
                                            />
                                        </Form.Check>
                                      </Form.Group>
                                    </div>

                                    <div className="form-group" style={{margin:'10px'}}>
                                      <label >Garde</label>
                                      <div>
                                        <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} value={this.state.typedegarde} onChange={this.selectusertypegarde}>
                                          {this.state.listtypegarde.map((typ) => (
                                            <option value={typ.idgarde}>{typ.typegarde}</option>
                                          ))}
                                        </Form.Select>
                                      </div>
                                    </div>

                                    <div className="form-group" style={{margin:'10px'}}>
                                      <label>Heure de garde: </label>
                                      <textarea rows={2} type="text" className="form-control" value={this.state.heuregarde} onChange={(e) => this.setState({ heuregarde: e.target.value})} />
                                      {/* 
                                      <div className="container" style={{marginTop:"20px", marginBottom:"20px"}}>
                                        <div className="row">
                                          <div className="col">De</div>
                                            <div className="col"><input type="text" className="form-control" value={this.state.heuregardede} onChange={(e) => this.setState({ heuregardede: e.target.value})} style={{width:"70px",marginTop:"-5px", marginLeft:"-60px"}}/></div>
                                            <div className="col">à</div><div className="col"><input type="text" className="form-control" value={this.state.heuregardea} onChange={(e) => this.setState({ heuregardea: e.target.value})} style={{width:"70px",marginTop:"-5px", marginLeft:"-60px"}}/></div>
                                          </div>
                                        </div>
                                      </div>
                                      */}                                   
                                    </div>

                                    <div className="form-group" style={{marginLeft:'10px',marginRight:'10px',marginTop:'10px',marginBottom:'10px'}}>
                                      <label>Casier judiciaire</label>
                                      <input type="file" accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg" className="form-control" onChange={this.onFileChangecj} />
                                      <a className="texte-clignote" href="https://casier-judiciaire.justice.gouv.fr/" target="_blank">Cliquez ici pour faire une demande de casier judiciaire</a>
                                    </div>

                                    <div className="form-group" style={{margin:'10px'}}>
                                      <label>Informations supplémentaires {parseInt(this.state.idtypeuser) !== 2? " concernant la garde de l'enfant":""}</label>
                                      <textarea rows={2} type="text" className="form-control" value={this.state.infosup} onChange={(e) => this.setState({ infosup: e.target.value})} />
                                    </div>

                                    <div className="form-group" style={{margin:'10px'}}>
                                      <label >Comment avez-vous connu NannyLou?</label>
                                      <div>
                                        <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} value={this.state.connaisancennl} onChange={this.selectconnaissance}>
                                          {this.state.listconnaissance.map((cns) => (
                                            <option value={cns.idconnaissance}>{cns.nnlreseau}</option>
                                          ))}
                                        </Form.Select>
                                      </div>
                                    </div>
                                    
                                    {parseInt(this.state.idtypeuser) === 2 ?

                                      <div className="form-group" style={{margin:'10px'}}>
                                          <Form.Group>
                                            <Form.Check type={"checkbox"}>
                                              <Form.Check.Label>Je consens à valider ce formulaire afin que ma candidature soit traitée par NannyLou.</Form.Check.Label>
                                                <Form.Check.Input
                                                  type={"checkbox"}
                                                  defaultChecked={this.state.validationformulaire}
                                                  onClick={(e) => {
                                                    this.setState({ validationformulaire: e.target.checked });
                                                  }}
                                                />
                                            </Form.Check>
                                          </Form.Group>
                                        </div>

                                        :

                                        <div className="form-group" style={{margin:'10px'}}>
                                          <Form.Group>
                                            <Form.Check type={"checkbox"}>
                                              <Form.Check.Label>Je consens à valider ce formulaire afin que mon inscription soit traité par NannyLou.</Form.Check.Label>
                                                <Form.Check.Input
                                                  type={"checkbox"}
                                                  defaultChecked={this.state.validationformulaire}
                                                  onClick={(e) => {
                                                    this.setState({ validationformulaire: e.target.checked });
                                                  }}
                                                />
                                            </Form.Check>
                                          </Form.Group>
                                        </div>

                                      }

                                      <div className="form-group" style={{margin:'10px'}}>
                                        <Form.Group>
                                          <Form.Check type={"checkbox"}>
                                            <Form.Check.Label>Je consens à recevoir des newsletters et des communications de la part de NannyLou.</Form.Check.Label>
                                              <Form.Check.Input
                                                type={"checkbox"}
                                                defaultChecked={this.state.newsletters}
                                                onClick={(e) => {
                                                  this.setState({ newsletters: e.target.checked });
                                                }}
                                              />
                                          </Form.Check>
                                        </Form.Group>
                                      </div>

                                      <div className="form-group" style={{margin:'10px'}}>
                                        <Form.Group>
                                          <Form.Check type={"checkbox"}>
                                            <Form.Check.Label>Je consens à recevoir des offres commerciales de vos partenaires (liés à la petite enfance).</Form.Check.Label>
                                              <Form.Check.Input
                                                type={"checkbox"}
                                                defaultChecked={this.state.offre}
                                                onClick={(e) => {
                                                  this.setState({ offre: e.target.checked });
                                                }}
                                              />
                                          </Form.Check>
                                        </Form.Group>
                                      </div>

                                      <div className="form-group" style={{margin:'10px'}}>
                                        <Form.Group>
                                          <Form.Check type={"checkbox"}>
                                            <Form.Check.Label>Je reconnais avoir pris connaissance et j'accepte les conditions générales d'utilisation.</Form.Check.Label>
                                              <Form.Check.Input
                                                type={"checkbox"}
                                                defaultChecked={this.state.cgu}
                                                onClick={(e) => {
                                                  this.setState({ cgu: e.target.checked });
                                                }}
                                              />
                                          </Form.Check>
                                        </Form.Group>
                                      </div>

                                  </div>

                                  :

                                  null

                            } 

                           

                      </MobileView>

                      {this.state.loadershow == true ?
                                

                            <Loader type="spinner-cub" bgColor={'#FF7E5A'} title={"Enregistrement en cours ..."} color={'#FF7E5A'} size={100} />
                              

                            :

                            null

                      }
                                                                  
                      <div className="d-flex justify-content-center">
                          <Button className="btn btn-danger"style={{ margin:"10px", width:'150px', marginRight:'50px'}} onClick={ () => this.setState({ismodalinscription:false})} variant="primary">Fermer</Button>
                          <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.inscription()} variant="success">Inscription</Button>   
                      </div>

                      </div>
                      
                  </Modal.Body>
                  </Modal>
                  
              </div>

            </Fade>
            </div>
            

            </div>
          );
        }
    
}

export default Nannies;
