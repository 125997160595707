import './App.css';
import './moncss.css';
import React,  {Component} from 'react';
import { isMobile} from "react-device-detect";
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

class Footer extends Component{
    
  render() {

          return (

                    <div className="footer">

                        <div className="container" >
                            <div className="row">
                                <div className="col-sm-2 d-flex justify-content-center">

                                    <img src={"https://www.nannylou.com/1haWxAdGFzdC5mZSIsImp0aSI6Im1haWxAdGFzdC5mZSIsImlzcyI6InVuc2FpZCIsImlhdCI6MTY4MzUyNT.png"} style={{width:"140px", height:"140px", borderRadius:"70px",marginTop:"16px"}}  alt="NannyLou"/>
                                
                                </div>
                                <div className="col-sm-3">
                                    <p style={{fontSize:'20px' , color: 'white',textAlign:"center",marginTop:20}}>Nos réseaux</p>
                                    <div className="text-center">
                                        
                                        <div  style={{marginTop:'0px'}}>
                                            <a href="https://web.facebook.com/NannyLouNNL" target="_blank"><FacebookIcon style={{fontSize:'30' , color: 'white', margin:5}}/></a>
                                            <a href="https://fr.linkedin.com/in/nanny-lou-b0b15424b" target="_blank" ><LinkedInIcon style={{fontSize:'30', color: 'white', margin:5}}/></a>
                                            <a href="https://instagram.com/nannylou__?igshid=YmMyMTA2M2Y=" target="_blank"><InstagramIcon style={{fontSize:'30', color: 'white', margin:5}}/></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3" style={{marginTop:isMobile?"20px":"0px"}}>

                                    <p style={{fontSize:'20px' , color: 'white',textAlign:"center",marginTop:20}}>Conditions</p>
                                    <a href="/CGU" to="/CGU" activeClass="active" className="nav-link" style={{color:"white", textDecoration:"none",fontSize:'14px',textAlign:"center"}}>Conditions générales d'utilisation</a>
                               
                                </div>
                                <div className="col-sm-3" style={{marginTop:isMobile?"20px":"0px"}}>

                                    <p style={{fontSize:'20px' , color: 'white',textAlign:"center",marginTop:20}}>Navigation</p>
                                    <a href="/Accueil" to="/Accueil" activeClass="active" className="nav-link" style={{color:"white", textDecoration:"none",fontSize:'16px',textAlign:"center"}}>Accueil</a>
                                    <a href="/Quisommesnous" to="/Quisommesnous" activeClass="active" className="nav-link" style={{color:"white", textDecoration:"none",fontSize:'16px',textAlign:"center"}}>Qui sommes-nous?</a>
                                    <a href="/Nannies" to="/Nannies" activeClass="active" className="nav-link" style={{color:"white", textDecoration:"none",fontSize:'16px',textAlign:"center"}}>Comment ça marche</a>
                                    <a href="/Contact" to="/Contact" activeClass="active" className="nav-link" style={{color:"white", textDecoration:"none",fontSize:'16px',textAlign:"center", marginBottom:"20px"}}>Contact</a>
                                   
                                </div>
                                
                                <div className="col-sm-2">
                                
                                </div>
                            </div>
                        </div>

                        <div style={{backgroundColor:"#FF97D3",height:"82px"}} className="d-flex justify-content-center">

                            <p style={{ color:'#7B4EFB',fontSize:"16px", textAlign:"center", marginTop:"22px"}}>
                                Copyright &copy; {new Date().getFullYear()} Tous les droits réservés à NannyLou.
                            </p>

                        </div>

                    </div>

          );

        }
    
}

export default Footer;
