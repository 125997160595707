import './App.css';
import './moncss.css';
import React,  {Component} from 'react';
import { Button} from 'react-bootstrap';
import { isBrowser} from "react-device-detect";
import * as ls from "local-storage";

class Paiementresult extends Component{
    
    constructor(props) {
    super(props);
    this.state = {
      nom:'',
      prenoms:'',
      mail:'',
      idtype:0,
      typeuser:'',
      iduser : 0,
      tariftype: '',
      montant:0.00,
      datapaiement:props.match.params.datapaiement,
    };
        
  }
    
    componentDidMount() {
     
      

    };

    componentWillMount() {
      
              
    };

    validation(){

      ls.set('tariftype', null);
      ls.set('montant', null);
      ls.set('typeoffre', null);

      document.location.href = '/Accueil';
    
    }
   
    
  render() {

          return (
              
                <div className="App" >

                    <div style={{paddingTop:'100px'}}>

                      {/*<p>{this.state.datapaiement}</p>
                      <p>Mail : {this.state.mail}</p>
                      <p>Idtype : {this.state.idtype}</p>
                      <p>Type user : {this.state.typeuser}</p>
                      <p>Id user : {this.state.iduser}</p>
                      <p>Tarif acheté : {this.state.tariftype}</p>
                      <p>Montant : {this.state.montant}</p>
                      */}
                      <p style={{fontSize:isBrowser? '3rem' : '2rem'}}>Votre paiement a été effectué avec succès.</p>
                      <div className="success-checkmark">
                        <div className="check-icon">
                          <span className="icon-line line-tip"></span>
                          <span className="icon-line line-long"></span>
                          <div className="icon-circle"></div>
                          <div className="icon-fix"></div>
                        </div>
                      </div>
                      <Button className="btn" style={{ background: 'linear-gradient(45deg, #f65ff3 30%, #7400ff 90%)',
                                  border: 0,
                                  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                                  height: 48,
                                  width:200,
                                  borderRadius:'25px'}} onClick={() => this.validation()} variant="success">OK</Button>

                    </div>

                </div>
          );
        }
    
}

export default Paiementresult;
